import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { Button, Modal, Card, Container, Row, Col } from "react-bootstrap";
import useApiAxios from "../../api/useApiAxios";
import { format } from 'date-fns';

const ViewVendor = () => {
  const location = useLocation();
  const { vendor } = location.state;
  const IMAGEENDPOINT = process.env.REACT_APP_IMG_URL;
  const { updateApprovalStatus ,declineVendorStatus} = useApiAxios();
  const [showApprovalModal, setShowApprovalModal] = useState(false);
  const [showImageModal, setShowImageModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  const handleApprove = async (vendorId) => {
    const data = { approvalStatus: "approved" };
    try {
      const response = await updateApprovalStatus(vendorId, data);
      console.warn("Vendor Approved:", response.data);
      vendor.approvalStatus = "approved"; // Update the vendor's approval status locally
      setShowApprovalModal(false);
    } catch (error) {
      console.error("Error approving vendor:", error);
    }
  };

  
  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
    setShowImageModal(true);
  };
  const formatDate = (date) => date ? format(new Date(date), 'MM/dd/yyyy') : "N/A";

  return (
    <Container className="my-5">
     
      <h2 className="pb-2 fw-bold">Vendor Details</h2>


      <Card className="mb-4 vendor-card">
        <Card.Body>
          <Row>
            <Col md={6}>
              <Card.Title>{vendor.businessName}</Card.Title>
              <Card.Text><strong>Contact Person:</strong> {vendor.contactPerson}</Card.Text>
              <Card.Text><strong>Contact Number:</strong> {vendor.contactNumber}</Card.Text>
              <Card.Text><strong>Email:</strong> {vendor.email}</Card.Text>
              <Card.Text><strong>Role:</strong> {vendor.role}</Card.Text>
              <Card.Text><strong>Category:</strong> {vendor.subCategory.name}</Card.Text>
              <Card.Text><strong>SubCategory:</strong> {vendor.category.name}</Card.Text>

              <Card.Text>
  <strong>Instagram:</strong> 
  {vendor.instagram && vendor.instagram !== "N/A" ? (
    <a href={vendor.instagram} target="_blank" rel="noopener noreferrer">
      {vendor.instagram}
    </a>
  ) : (
    "N/A"
  )}
</Card.Text>

<Card.Text>
  <strong>Facebook:</strong> 
  {vendor.facebook && vendor.facebook !== "N/A" ? (
    <a href={vendor.facebook} target="_blank" rel="noopener noreferrer">
      {vendor.facebook}
    </a>
  ) : (
    "N/A"
  )}
</Card.Text>

<Card.Text>
  <strong>TikTok:</strong> 
  {vendor.tikTok && vendor.tikTok !== "N/A" ? (
    <a href={vendor.tikTok} target="_blank" rel="noopener noreferrer">
      {vendor.tikTok}
    </a>
  ) : (
    "N/A"
  )}
</Card.Text>

<Card.Text>
  <strong>Twitter:</strong> 
  {vendor.twitter && vendor.twitter !== "N/A" ? (
    <a href={vendor.twitter} target="_blank" rel="noopener noreferrer">
      {vendor.twitter}
    </a>
  ) : (
    "N/A"
  )}
</Card.Text>
<Card.Text><strong>Liquor License Expiry Date:</strong> {formatDate(vendor.liquorLicenseExpiryDate)}</Card.Text>
              <Card.Text><strong>Expire License Date:</strong> {formatDate(vendor.expireLicenseDate)}</Card.Text>
              <Card.Text><strong>Insurance Expiry Date:</strong> {formatDate(vendor.insuranceExpiryDate)}</Card.Text>
              <Card.Text><strong>Other License Expiry Date:</strong> {formatDate(vendor.otherLicenseExpiryDate)}</Card.Text>


            </Col>
            <Col md={6}>
              <Card.Text><strong>Created At:</strong> {format(new Date(vendor.createdAt), 'MM/dd/yyyy HH:mm:ss')}</Card.Text>
              <Card.Text><strong>Updated At:</strong> {format(new Date(vendor.updatedAt), 'MM/dd/yyyy HH:mm:ss')}</Card.Text>
              <Card.Text><strong>Business Description:</strong> {vendor.businessDescription}</Card.Text>
              <Card.Text><strong>Add Product Message:</strong> {vendor.addProductMessage}</Card.Text>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <h2 className="text-center mb-4">Images</h2>
      <Row>
        {["productImage1", "productImage2", "productImage3", "publicLiabilityInsurance", "foodRegistrationImage", "liquorLicenseImage", "otherLicenseImage"].map((imageKey, idx) => (
          vendor[imageKey] ? (
            <Col md={4} key={idx} className="mb-4">
              <Card className="image-card">
                <Card.Img
                  variant="top"
                  src={`${IMAGEENDPOINT}/${vendor[imageKey]}`}
                  onClick={() => handleImageClick(`${IMAGEENDPOINT}/${vendor[imageKey]}`)}
                  style={{ cursor: "pointer" }}
                />
                <Card.Body>
                  <Card.Text><strong>{imageKey.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase()).replace(' Image', '')}</strong></Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ) : null
        ))}
      </Row>

      <div className="text-center"> 
        <Button
          variant={
            vendor.approvalStatus.toLowerCase() === "approved"
              ? "success"
              : "primary"
          }
          onClick={() => setShowApprovalModal(true)}
          disabled={vendor.approvalStatus.toLowerCase() === "approved"}
        >
          {vendor.approvalStatus.toLowerCase() === "approved"
            ? "Approved"
            : "Approve Vendor"}
        </Button>
      </div>

     
      <Modal show={showApprovalModal} onHide={() => setShowApprovalModal(false)}>
        <Modal.Header>
          <Modal.Title>Confirm Approval</Modal.Title>
          <button type="button" class="close" data-dismiss="modal" style={{ fontSize: "36px" }}aria-label="Close" onClick={() => setShowApprovalModal(false)}>
          <span aria-hidden="true">&times;</span>
        </button>

        </Modal.Header>
        <Modal.Body>Are you sure you want to approve this vendor?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowApprovalModal(false)}>
            No
          </Button>
          <Button variant="primary" onClick={() => handleApprove(vendor._id)}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

      
      <Modal show={showImageModal} onHide={() => setShowImageModal(false)} size="lg">
        <Modal.Header>
          <Modal.Title>Image Preview</Modal.Title>
          <button type="button" class="close" data-dismiss="modal" style={{ fontSize: "36px" }} aria-label="Close" onClick={() => setShowImageModal(false)}>
          <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <img
            src={selectedImage}
            alt="Selected"
            style={{ width: "100%", height: "auto" }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowImageModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <style>
        {`
          .image-card {
            border-radius: 10px;
            overflow: hidden;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
            max-width: 100%; /* Ensures card does not exceed container width */
            height: auto; /* Automatically adjusts height based on content */
          }

          .image-card .card-img-top {
            height: 400px; 
            width: 100%;
            object-fit: cover; 
            cursor: pointer; /* Indicates image is clickable */
          }

          .image-card .card-body {
            padding: 1rem;
          }

          .text-center {
            text-align: center;
          }

          .mb-4 {
            margin-bottom: 1.5rem;
          }

          .my-5 {
            margin: 3rem 0;
          }

          .modal-header .close {
            padding: 0.75rem 1.25rem;
            margin: -0.75rem -1.25rem -0.75rem auto;
            border: 0;
            background: none;
            font-size: 1.5rem;
            color: #000;
          }
        `}
      </style>

    </Container>
  );
};

export default ViewVendor;
