import React from "react";
import { useContext, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import useApiAxios from "../api/useApiAxios";
import { useFormik } from "formik";
import * as Yup from "yup";
import Loader from "../components/loader/Loader";
import { useForm } from "react-hook-form";

const Login = () => {
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({}),
    onSubmit: async (values, { resetForm }) => {
      console.log(values);
      await loginApi(values);
    },
  });
  const { loginApi } = useApiAxios();

  const [showPassword, setShowPassword] = useState(false);
  const onSubmit = async (data) => {
    await loginApi(data);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="d-lg-flex half">
      <div
        className="bg order-md-2 d-none d-lg-block"
        style={{
          backgroundImage: 'url("assets/images/login.png")',
          backgroundSize: 'covers', 
          backgroundRepeat: 'no-repeat',
          height: '100vh', 
          backgroundPosition:'bottom',

        }}
      />
      <div className="contents">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-md-7">
              <h3>
                Login to <strong>Eventease360</strong>
              </h3>
              <p className="mb-4">Enter your login credentials</p>
              <form action="#" method="post" onSubmit={formik.handleSubmit}>
                <div className="form-group first">
                  <label htmlFor="username">Email</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="your-email@gmail.com"
                    id="email"
                    onChange={formik.handleChange("email")}
                    required
                  />
                </div>
                <div className="form-group last mb-3">
                  <label htmlFor="password">Password</label>
                  <div className="input-group">
                    <input
                      type={showPassword ? "text" : "password"}
                      className="form-control"
                      placeholder="Your Password"
                      id="password"
                      onChange={formik.handleChange("password")}
                    />
                    <div className="input-group-append">
                      <span className="input-group-text">
                        <i
                          className={`toggle-password ${
                            showPassword ? "fa fa-eye" : "fa fa-eye-slash"
                          }`}
                          onClick={togglePasswordVisibility}
                          style={{ cursor: "pointer" }}
                        ></i>
                      </span>
                    </div>
                  </div>
                </div>

                <div className="d-flex mb-5 align-items-center">
                  
                  <span className="ml-auto">
                    <Link to="/forgetPassword" className="forgot-password-link">
                      Forgot password?
                    </Link>
                  </span>
                </div>
                <button
                  name="login"
                  id="login"
                  className="btn btn-primary w-100 mb-4"
                  type="submit"
                  defaultValue="Login"
                >
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
