// import React, { useRef, useState, useEffect } from "react";
// import Modal from "react-modal";
// import { fabric } from "fabric";
// import "./ImageModal.css";
// import useApiAxios from "../../api/useApiAxios";

// const MapModal = ({ isOpen, onRequestClose, marketdateId, mapImage ,selectedDate,dates ,selectedMarketData}) => {


//   console.warn("OOOOOOOOOOOOOOOOOOOOOO",selectedMarketData)
 

//   const { postMapImage } = useApiAxios();
//   const canvasRef = useRef(null);
//   const fabricCanvasRef = useRef();
//   const [vendorIndex, setVendorIndex] = useState(1);
//   const [imageLoaded, setImageLoaded] = useState(false);
//   const [first, setFirst] = useState(0);
//   const [matchedData, setMatchedData] = useState(null); // New state to hold matched data

//   console.warn("map images", mapImage);

//   const IMAGEENDPOINT = process.env.REACT_APP_IMG_URL;



//   useEffect(() => {
//     if (isOpen) {
//       setVendorIndex(1);

//       if (mapImage) {
//         setImageLoaded(true);
//         initializeCanvas();
//       }
//     } else {
//       disposeCanvas();
//     }

//     return () => {
//       disposeCanvas();
//     };
//   }, [isOpen, mapImage, first]);
//   const loadMapImage = (imageSrc) => {
//     const fullImageUrl = IMAGEENDPOINT + "/" + imageSrc;
//     const fabricCanvas = fabricCanvasRef.current;
  
//     if (!fabricCanvas) {
//       return;
//     }
  
//     const img = new Image();
//     img.src = fullImageUrl;
  
//     img.crossOrigin = "anonymous";
  
//     img.onload = () => {
//       try {
//         setImageLoaded(true);
  
//         // Get modal dimensions
//         const modalWidth = document.querySelector(".map-modal").offsetWidth;
//         const modalHeight = document.querySelector(".map-modal").offsetHeight;
  
//         // Set the maximum dimensions of the canvas inside the modal
//         const maxWidth = modalWidth - 60; // Adjust for padding/margins
//         const maxHeight = modalHeight - 160; // Adjust for header/footer
  
//         // Calculate the scaling factor to fit the image within the modal
//         let scaleX = 1;
//         let scaleY = 1;
//         if (img.width > maxWidth) {
//           scaleX = maxWidth / img.width;
//         }
//         if (img.height > maxHeight) {
//           scaleY = maxHeight / img.height;
//         }
//         const scale = Math.min(scaleX, scaleY);
  
//         // Calculate the canvas size based on the scaled image size
//         const canvasWidth = img.width * scale;
//         const canvasHeight = img.height * scale;
  
//         // Set the canvas dimensions
//         fabricCanvas.setWidth(canvasWidth);
//         fabricCanvas.setHeight(canvasHeight);
  
//         // Create the fabric image with the calculated scale
//         const fabricImage = new fabric.Image(img, {
//           left: 0,
//           top: 0,
//           originX: "left",
//           originY: "top",
//           scaleX: scale,
//           scaleY: scale,
//           selectable: false,
//           evented: false,
//         });
  
//         // Add the image to the canvas and send it to the background
//         fabricCanvas.add(fabricImage);
//         fabricCanvas.sendToBack(fabricImage);
  
//         // Add event listeners to the canvas if needed
//         fabricCanvas.on("mouse:down", handleCanvasClick);
//       } catch (error) {
//         console.error("Error loading the image onto the fabric canvas:", error);
//       }
//     };
  
//     img.onerror = (error) => {
//       setImageLoaded(false);
//       console.error("Failed to load image:", error);
//     };
//   };
  

//   const initializeCanvas = () => {
//     const canvas = canvasRef.current;
//     console.log(canvas, fabricCanvasRef.current);
//     if (canvas && !fabricCanvasRef.current) {
//       fabricCanvasRef.current = new fabric.Canvas(canvas);
//       if (mapImage) {
//         loadMapImage(mapImage);
//       }
//     } else {
//       setFirst((pre) => setFirst(pre + 1));
//     }
//   };
//   console.log(first);
//   const disposeCanvas = () => {
//     const fabricCanvas = fabricCanvasRef.current;
//     if (fabricCanvas) {
//       fabricCanvas.off("mouse:down", handleCanvasClick);
//       fabricCanvas.dispose();
//       fabricCanvasRef.current = null;
//     }
//   };

//   const handleCanvasClick = (event) => {
//     const { x, y } = event.pointer;
//     const fabricCanvas = fabricCanvasRef.current;
//     let shapeClicked = false;

//     fabricCanvas.forEachObject((obj) => {
//       if (obj.type === "group") {
//         const { left, top } = obj;
//         const circle = obj.item(0);
//         const radius = circle.radius * circle.scaleX;

//         const distance = Math.sqrt(
//           Math.pow(x - left, 2) + Math.pow(y - top, 2)
//         );

//         if (distance <= radius) {
//           if (window.confirm("Do you want to delete this shape?")) {
//             fabricCanvas.remove(obj);
//           }
//           shapeClicked = true;
//           return;
//         }
//       }
//     });

//     if (!shapeClicked) {
//       addShapeToCanvas({ x, y });
//     }
//   };

//   const addShapeToCanvas = ({ x, y }) => {
//     const fabricCanvas = fabricCanvasRef.current;
//     if (fabricCanvas) {
//       const circleRadius = 10;

//       setVendorIndex((prevIndex) => {
//         const currentVendorIndex = prevIndex;

//         const text = new fabric.Text(currentVendorIndex.toString(), {
//           fontSize: 20,
//           fill: "white",
//           originX: "center",
//           originY: "center",
//           evented: false,
//         });

//         const circle = new fabric.Circle({
//           radius: circleRadius,
//           fill: "rgba(0,0,0)",
//           originX: "center",
//           originY: "center",
//           evented: false,
//         });

//         const group = new fabric.Group([circle, text], {
//           left: x,
//           top: y,
//           originX: "center",
//           originY: "center",
//         });

//         fabricCanvas.add(group);

//         return currentVendorIndex + 1;
//       });
//     }
//   };

//   const saveCanvasAsImage = async () => {
//     const fabricCanvas = fabricCanvasRef.current;
//     if (fabricCanvas) {
//       const mapData = JSON.stringify(fabricCanvas.toJSON());
//       const canvasImageURL = fabricCanvas.toDataURL({
//         format: "png",
//         quality: 1,
//       });
//       const imageBlob = await fetch(canvasImageURL).then((res) => res.blob());
//       // Prepare form data
//       const formData = new FormData();
//       formData.append("marketdateId", marketdateId);
//       formData.append("mapImage", imageBlob, "canvas_image.png");
//       formData.append("mapData", mapData);

//       const response = await postMapImage(formData);
//       if (!response.error) {
//         onRequestClose();
//       }
//     } else {
//     }
//   };

//   return (
//     <Modal
//       isOpen={isOpen}
//       onRequestClose={onRequestClose}
//       contentLabel="Map Modal"
//       className="map-modal mt-4"
//       overlayClassName="map-modal-overlay"
//     >
//       <div className="modal-contentimage">
//         <h2>Upload Market Map</h2>
//         <div className="canvas-container">
//           {/* {imageLoaded ? (
//             <canvas ref={canvasRef} />
//           ) : (
//             <div className="dummy-message">Map not added</div>
//           )} */}
//           <canvas ref={canvasRef}
//           />
//         </div>
//         <button
//           type="button"
//           onClick={saveCanvasAsImage}
//           className="btn btn-primary mt-3"
//         >
//           Save Image
//         </button>
//         <button
//           type="button"
//           className="btn btn-danger mt-2"
//           onClick={onRequestClose}
//         >
//           Close
//         </button>
//       </div>
//     </Modal>
//   );
// };

// export default MapModal;

import React, { useRef, useState, useEffect } from "react";
import Modal from "react-modal";
import { fabric } from "fabric";
import "./ImageModal.css";
import useApiAxios from "../../api/useApiAxios";

const MapModal = ({ isOpen, onRequestClose, marketdateId, selectedMarketData, selectedDate, mapImage }) => {
  console.warn("Ssssssssssss",mapImage)

  const mapDataaaaa = selectedDate?.mapData
  const { postMapImage } = useApiAxios();
  const canvasRef = useRef(null);
  const fabricCanvasRef = useRef();
  const [vendorIndex, setVendorIndex] = useState(1);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [first, setFirst] = useState(0);

  const IMAGEENDPOINT = process.env.REACT_APP_IMG_URL;

  useEffect(() => {
    if (isOpen) {
      setVendorIndex(1);

      if (selectedMarketData && selectedMarketData.mapImage) {
        setImageLoaded(true);
        initializeCanvas(selectedMarketData.mapImage);
      }
    } else {
      disposeCanvas();
    }

    return () => {
      disposeCanvas();
    };
  }, [isOpen, selectedMarketData, first]);

  const loadMapImage = (mapImage) => {
    const fullImageUrl = IMAGEENDPOINT + "/" + mapImage;
    const fabricCanvas = fabricCanvasRef.current;

    if (!fabricCanvas) {
        return;
    }

    const img = new Image();
    img.src = fullImageUrl;
    img.crossOrigin = "anonymous";

    img.onload = () => {
        try {
            setImageLoaded(true);

            const modalWidth = document.querySelector(".map-modal").offsetWidth;
            const modalHeight = document.querySelector(".map-modal").offsetHeight;
            const maxWidth = modalWidth - 60;
            const maxHeight = modalHeight - 160;

            let scaleX = 1;
            let scaleY = 1;
            if (img.width > maxWidth) {
                scaleX = maxWidth / img.width;
            }
            if (img.height > maxHeight) {
                scaleY = maxHeight / img.height;
            }
            const scale = Math.min(scaleX, scaleY);

            const canvasWidth = img.width * scale;
            const canvasHeight = img.height * scale;

            fabricCanvas.setWidth(canvasWidth);
            fabricCanvas.setHeight(canvasHeight);

            // Clear the canvas before loading the new image
            fabricCanvas.clear();

            // Create the fabric image
            const fabricImage = new fabric.Image(img, {
                left: 0,
                top: 0,
                originX: "left",
                originY: "top",
                scaleX: scale,
                scaleY: scale,
                selectable: false,
                evented: false,
            });

            // Set the image as the background
            fabricCanvas.setBackgroundImage(fabricImage, fabricCanvas.renderAll.bind(fabricCanvas));

            // Load the existing map data if available
            if (mapDataaaaa) {
                fabricCanvas.loadFromJSON(JSON.parse(mapDataaaaa), fabricCanvas.renderAll.bind(fabricCanvas));
            }

            fabricCanvas.on("mouse:down", handleCanvasClick);
        } catch (error) {
            console.error("Error loading the image onto the fabric canvas:", error);
        }
    };

    img.onerror = (error) => {
        setImageLoaded(false);
        console.error("Failed to load image:", error);
    };
};


  const initializeCanvas = (mapImage) => {
    const canvas = canvasRef.current;
    if (canvas && !fabricCanvasRef.current) {
      fabricCanvasRef.current = new fabric.Canvas(canvas);
      if (mapImage) {
        loadMapImage(mapImage);
       
      }
      
  // re-render the canvas
    } else {
      setFirst((prev) => prev + 1);
    }
  };

  const disposeCanvas = () => {
    const fabricCanvas = fabricCanvasRef.current;
    if (fabricCanvas) {
      fabricCanvas.off("mouse:down", handleCanvasClick);
      fabricCanvas.dispose();
      fabricCanvasRef.current = null;
    }
  };

  const handleCanvasClick = (event) => {
    const { x, y } = event.pointer;
    const fabricCanvas = fabricCanvasRef.current;
    let shapeClicked = false;

    fabricCanvas.forEachObject((obj) => {
      if (obj.type === "group") {
        const { left, top } = obj;
        const circle = obj.item(0);
        const radius = circle.radius * circle.scaleX;

        const distance = Math.sqrt(
          Math.pow(x - left, 2) + Math.pow(y - top, 2)
        );

        if (distance <= radius) {
          if (window.confirm("Do you want to delete this shape?")) {
            fabricCanvas.remove(obj);
          }
          shapeClicked = true;
          return;
        }
      }
    });

    if (!shapeClicked) {
      addShapeToCanvas({ x, y });
    }
  };

  const addShapeToCanvas = ({ x, y }) => {
    const fabricCanvas = fabricCanvasRef.current;
    if (fabricCanvas) {
      const circleRadius = 10;

      setVendorIndex((prevIndex) => {
        const currentVendorIndex = prevIndex;

        const text = new fabric.Text(currentVendorIndex.toString(), {
          fontSize: 20,
          fill: "white",
          originX: "center",
          originY: "center",
          evented: false,
        });

        const circle = new fabric.Circle({
          radius: circleRadius,
          fill: "rgba(0,0,0)",
          originX: "center",
          originY: "center",
          evented: false,
        });

        const group = new fabric.Group([circle, text], {
          left: x,
          top: y,
          originX: "center",
          originY: "center",
        });

        fabricCanvas.add(group);

        return currentVendorIndex + 1;
      });
    }
  };

  const saveCanvasAsImage = async () => {
    const fabricCanvas = fabricCanvasRef.current;
    if (fabricCanvas) {
      const mapData = JSON.stringify(fabricCanvas.toJSON());
      const canvasImageURL = fabricCanvas.toDataURL({
        format: "png",
        quality: 1,
      });
      const imageBlob = await fetch(canvasImageURL).then((res) => res.blob());
      const formData = new FormData();
      formData.append("marketdateId", marketdateId);
      formData.append("mapImage", imageBlob, "canvas_image.png");
      formData.append("mapData", mapData);

      const response = await postMapImage(formData);
      if (!response.error) {
        onRequestClose();
      }
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Map Modal"
      className="map-modal mt-4"
      overlayClassName="map-modal-overlay"
    >
      <div className="modal-contentimage">
        <h2>Upload Market Map</h2>
        <div className="canvas-container">
          <canvas ref={canvasRef} />
        </div>
        <button
          type="button"
          onClick={saveCanvasAsImage}
          className="btn btn-primary mt-3"
        >
          Save Image
        </button>
        <button
          type="button"
          className="btn btn-danger mt-2"
          onClick={onRequestClose}
        >
          Close
        </button>
      </div>
    </Modal>
  );
};

export default MapModal;
