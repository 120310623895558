import React, { useState, useEffect, useContext } from "react";
import { Modal, Button } from "react-bootstrap";
import useApiAxios from "../../api/useApiAxios";
import moment from "moment";
import { Context } from "../../context/Context";
import ReactPaginate from "react-paginate";

const VendorDate = () => {
  const { getDate, postVendorDateApply } = useApiAxios();
  const [marketData, setMarketData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const { userData } = useContext(Context);
  const vendorId = userData.vendor._id;
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const vendorDatePerPageOptions = [5, 10, 20, 50];

  const fetchMarketData = async () => {
    try {
      setIsLoading(true);
      const { error, data } = await getDate();
      if (!error) {
        const totalItems = data.data.length;
        setTotalPages(Math.ceil(totalItems / itemsPerPage));
        setMarketData(
          data.data.slice(
            currentPage * itemsPerPage,
            (currentPage + 1) * itemsPerPage
          )
        );
      } else {
        console.error("Error fetching market data:", data);
        setModalMessage("Error fetching market data. Please try again later.");
        setShowModal(true);
      }
    } catch (error) {
      console.error("Error fetching market data:", error);
      setModalMessage("Error fetching market data. Please try again later.");
      setShowModal(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchMarketData();
  }, [currentPage, itemsPerPage]);

  const handleApplyDate = async (dateId) => {
    try {
      const payload = { dateId };
      const { error, data } = await postVendorDateApply(payload);
      if (!error) {
        await fetchMarketData();
        setModalMessage(
          "You have successfully applied the date. Wait for approval from the market end."
        );
        setShowModal(true);
      } else {
        console.error("Error applying date:", data);
        setModalMessage("Error applying date. Please try again later.");
        setShowModal(true);
      }
    } catch (error) {
      console.error("Error:", error);
      setModalMessage("Error applying date. Please try again later.");
      setShowModal(true);
    }
  };

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
  };

  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(Number(event.target.value));
    setCurrentPage(0); // Reset to the first page
  };

  return (
    <>
      <style>
        {`
          .btn-custom {
            color: #fff;
            border: none; /* Remove border */
            padding: 10px 20px; /* Add padding */
            font-size: 14px; /* Font size */
            cursor: pointer; /* Pointer cursor */
          }

          .btn-apply {
            background-color: #3e8ede;
          }

          .btn-applied {
            background-color: #ff6f61;
          }

          .btn-apply:hover {
            background-color: #3e8ede;
          }

          .btn-applied:hover {
            background-color: #ff6f61;
          }

          .form-button-action {
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .btn-custom:disabled {
            opacity: 0.65;
            cursor: not-allowed; 
          }

          .pagination-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 20px;
          }

          .pagination-container .page-info {
            font-size: 14px;
            font-weight: bold;
          }

          .pagination-container .items-per-page-dropdown {
            display: flex;
            align-items: center;
          }

          .pagination-container .items-per-page-dropdown label {
            margin-right: 10px;
          }

          .pagination-container .items-per-page-dropdown select {
            padding: 5px;
          }

          .pagination-container .pagination {
            display: flex;
            justify-content: center;
            margin-top: 10px;
          }

          .pagination-container .pagination .active {
            font-weight: bold;
          }
        `}
      </style>
      <div className="page-inner">
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-header">
                <div className="card-title">View Date Details</div>
              </div>
              <div className="card-body">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Total Stall</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {marketData &&
                      marketData.map((item, index) => (
                        <tr key={index}>
                          <td>{moment(item.date).format("MMMM Do YYYY")}</td>
                          <td>{item.totalStall || "-"}</td>
                          <td>
                            <div className="form-button-action">
                              <button
                                type="button"
                                className={`btn btn-custom ${
                                  item.applied ? "btn-applied" : "btn-apply"
                                }`}
                                onClick={() => handleApplyDate(item._id)}
                                disabled={item.applied}
                              >
                                {item.applied ? "Applied" : "Apply Date"}
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>

               
                  <div className="d-flex justify-content-between align-items-center pagination-container">
                    <ReactPaginate
                      previousLabel={"Previous"}
                      nextLabel={"Next"}
                      breakLabel={"..."}
                      pageCount={totalPages}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={handlePageChange}
                      containerClassName={"pagination"}
                      activeClassName={"active"}
                    />

                    <div className="form-group d-flex align-items-center items-per-page">
                      <label htmlFor="vendorsPerPage">Vendors per page: </label>
                      <select
                        value={itemsPerPage}
                        onChange={handleItemsPerPageChange}
                        className="form-control m-2"
                        style={{ width: "auto", display: "inline-block" }}
                      >
                        {vendorDatePerPageOptions.map((option) => (
                          <option key={option} value={option}>
                            Show {option}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
               
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header>
          <Modal.Title>
            {modalMessage.includes("Error") ? "Error" : "Success"}
          </Modal.Title>
          <button
            type="button"
            className="close"
            onClick={() => setShowModal(false)}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body>{modalMessage}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default VendorDate;
