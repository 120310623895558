import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { Context } from "../../context/Context";
import SubAdminSidebarData from "./SubAdminSidebar.json";
import VendorSidebarData from "./VendorSideBar.json"

const SideBar = () => {
  const { userData } = useContext(Context);

  const sidebarData = userData.role === "vendor" ? VendorSidebarData : SubAdminSidebarData;

  return (
    <div className="sidebar sidebar-style-2">
      <div className="sidebar-wrapper scrollbar scrollbar-inner">
        <div className="sidebar-content">
          <div className="user">
            <div className="avatar-sm float-left mr-2">
              <img
                src="/assets/img/profile.jpg"
                alt="..."
                className="avatar-img rounded-circle"
              />
            </div>
            <div className="info">
              <a
                data-toggle="collapse"
                href="#collapseExample"
                aria-expanded="true"
              >
                <span>
                {userData.name ? userData.name : userData.vendor.businessName}
                <span className="user-level">{userData.email ? userData.email : userData.email}</span>
                  {/* <span className="caret" /> */}
                </span>
              </a>
            </div>
          </div>
          <ul className="nav nav-primary">
          {sidebarData.menuItems.map((menuItem, index) => (
            <li className="nav-item" key={index}>
              {menuItem.subMenu ? (
                <React.Fragment>
                  <a
                    data-toggle="collapse"
                    href={`#submenu-${index}`}
                  >
                    <i className={menuItem.icon} />
                    <p>{menuItem.title}</p>
                    <span className="caret" />
                  </a>
                  <div className="collapse" id={`submenu-${index}`}>
                    <ul className="nav nav-collapse">
                      {menuItem.subMenu.map((subItem, subIndex) => (
                        <li key={subIndex}>
                          <Link to={subItem.link}>
                            <span className="sub-item">{subItem.title}</span>
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                </React.Fragment>
              ) : (
                <Link to={menuItem.link}>
                  <i className={menuItem.icon} />
                  <p>{menuItem.title}</p>
                </Link>
              )}
            </li>
          ))}
        </ul>
        </div>
      </div>
    </div>
  );
};

export default SideBar;
