import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import useApiAxios from "../../api/useApiAxios";
import SunEditor from "suneditor-react";
import DragsAndDrop from "../../image/DragsAndDrop";

const EditSectionModal = ({ show, handleClose, sectionData, handleEditSection }) => {
    const { _id, title, description, image } = sectionData;
    console.warn("OOOOOOOOOOOOOOOOO",sectionData)
    const [newImage, setNewImage] = useState(null);
    const { updateSectionImageApi } = useApiAxios();
    const imageUrl = process.env.REACT_APP_IMG_URL;
    const [updatedTitle, setUpdatedTitle] = useState("");
    const [updatedDescription, setUpdatedDescription] = useState("");

    // Set initial values for title and description when modal shows
    useEffect(() => {
        setUpdatedTitle(title);
        setUpdatedDescription(description);
    }, [title, description]);

    const handleImageChange = (file) => {
        // const file = event.target.files[0];
        setNewImage(file);
    };

    const handleUploadImage = async () => {
        if (!newImage) return;

        try {
            const formData = new FormData();
            formData.append("image", newImage);

            const result = await updateSectionImageApi(_id, formData);

            if (result.success) {
                setNewImage(null);
            } else {
                console.error("Failed to upload image:", result.error);
            }
        } catch (error) {
            console.error("Error uploading image:", error);
        }
    };

    const handleSaveChanges = () => {
        const updatedSectionData = {
            title: updatedTitle,
            description: updatedDescription
        };
        handleEditSection(_id, updatedSectionData);
        handleClose();

    };

    return (
        <Modal show={show} onHide={handleClose}>
            
            <Modal.Header>
        <Modal.Title>Edit Section</Modal.Title>
        <Button variant="link" className="close close-button" style={{ fontSize: "30px" }}  onClick={handleClose}>
        <span aria-hidden="true">&times;</span>
    </Button>
    </Modal.Header>
            <Modal.Body>
                <form>
                    <div className="mb-3">
                        <label htmlFor="editTitle" className="form-label">
                            Title
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="editTitle"
                            value={updatedTitle}
                            onChange={(e) => setUpdatedTitle(e.target.value)}
                        />
                    </div>





                    
                    <div className="mb-3">
                        <label htmlFor="editDescription" className="form-label">
                            Description
                        </label>
                        <SunEditor
                        setOptions={{
                          buttonList: [
                            ["undo", "redo"],
                            ["font", "fontSize", "formatBlock"],
                            ["bold", "underline", "italic", "strike", "subscript", "superscript"],
                            ["fontColor", "hiliteColor"],
                            ["indent", "outdent"],
                            ["align", "horizontalRule", "list", "table"],
                            ["link", "image", "video"],
                            ["fullScreen", "showBlocks", "codeView"],
                          ],
                        }}
                        onChange={setUpdatedDescription}
                        setContents={sectionData.description}

                      />
                    </div>

                   
                   
                    <div className="mb-3">
                        <label htmlFor="newImage" className="form-label">
                            Select New Image
                        </label>
                        
                        <DragsAndDrop
                          heading="Upload Image"
                          inputName="Image"
                          imgKey={image}
                          aspect={2 / 2}
                          uploadFile={(x) => {
                            handleImageChange(x);
                          }}
                        />
                    </div>
                </form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button
                    variant="primary"
                    onClick={handleUploadImage}
                    disabled={!newImage}
                >
                    Upload Image
                </Button>
                <Button
                    variant="primary"
                    onClick={handleSaveChanges}
                    disabled={!updatedTitle || !updatedDescription} // Enable save button when title and description are not empty
                >
                    Save Changes
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default EditSectionModal;
