import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import useApiAxios from "../../api/useApiAxios";
import { Link, useLocation } from "react-router-dom";

const EditVendor = () => {
  const location = useLocation();
  const vendor = location.state?.vendor;
  console.warn("vendor data", vendor)

  const { updateVendorApi } = useApiAxios();
const initialValues = {
    businessName: vendor ? vendor.businessName : "",
    email: vendor ? vendor.email : "",
    // Add other initial values as necessary
    _id: vendor ? vendor._id : "",
    // Add other fields here
  };

  const validationSchema = Yup.object().shape({
    businessName: Yup.string()
      .matches(/^[A-Za-z][A-Za-z ]*$/, "Name must start with an alphabet and can contain spaces only between alphabets")
      .required("Name is required")
      .max(25, "Name must be at most 25 characters")
  });
  

  const onSubmit = async (values, { setSubmitting }) => {
    try {
      const updatedValues = { ...values, email: vendor.email }; // Ensure email stays unchanged
      await updateVendorApi(vendor._id, updatedValues);
    } catch (error) {
      console.error("Error updating vendor:", error);
    }
    setSubmitting(false);
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });
  console.warn("PPPPPPP___initial valie___Pppp",initialValues)

  return (
    <>
      <div className="panel-header">
        <div className="page-inner py-5">
          <div className="d-flex align-items-left align-items-md-center flex-column flex-md-row">
            <div>
              <h2 className="pb-2 fw-bold">Edit Vendor</h2>
              <ul className="breadcrumbs">
                <li className="nav-home">
                  <Link to="/">
                    <i className="flaticon-home" />
                  </Link>
                </li>
                <li className="separator">
                  <i className="flaticon-right-arrow" />
                </li>
                <li className="nav-item">
                  <a href="#">Vendor</a>
                </li>
                <li className="separator">
                  <i className="flaticon-right-arrow" />
                </li>
                <li className="nav-item">
                  <a href="#">Edit Vendor</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="page-inner mt--5">
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-header">
                <div className="d-flex align-items-center">
                  <h4 className="card-title">Vendor Details</h4>
                </div>
              </div>
              <div className="card-body">
                <form onSubmit={formik.handleSubmit}>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="businessName">Business Name</label>
                        <input
                          type="businessName"
                          id="businessName"
                          name="businessName"
                          className={`form-control ${
                            formik.errors.businessName && formik.touched.businessName
                              ? "is-invalid"
                              : ""
                          }`}
                          value={formik.values.businessName}
                          onChange={formik.handleChange}
                        />
                        {formik.errors.businessName && formik.touched.businessName && (
                          <div className="invalid-feedback">
                            {formik.errors.businessName}
                          </div>
                        )}
                      </div>
                      <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <input
                          type="email"
                          id="email"
                          name="email"
                          className={`form-control ${
                            formik.errors.email && formik.touched.email
                              ? "is-invalid"
                              : ""
                          }`}
                          value={formik.values.email}
                          disabled
                        />
                        {formik.errors.email && formik.touched.email && (
                          <div className="invalid-feedback">
                            {formik.errors.email}
                          </div>
                        )}
                      </div>
                    </div>
          
                  </div>
                  <button type="submit" className="btn btn-primary">
                    Save
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditVendor;
