import React, { useState, useEffect, useRef } from "react";
import { useFormik } from "formik";
import { useNavigate, useParams, Link } from "react-router-dom";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css"; // Import SunEditor's CSS
import SectionInput from "./SectionInput.js"; // Ensure this path is correct
import useApiAxios from "../../api/useApiAxios";
import { HexColorPicker } from "react-colorful"; // Import HexColorPicker
import * as Yup from "yup"; // Import Yup
import DragsAndDrop from "../../image/DragsAndDrop.jsx";

const AddSubAdminLandingPageDetail = () => {
  const { id } = useParams();

  const { createSubAdminDetailApi, UploadSectionImageApi } = useApiAxios();
  const navigate = useNavigate();

  const validationSchema = Yup.object({
    mainTitle: Yup.string()
      .max(100, "Main Title must be 100 characters or less")
      .required("Main Title is required"),
    title: Yup.string()
      .max(100, "Title must be 100 characters or less")
      .required("Title is required"),
    // description: Yup.string()
    //   // .max(
    //   //   MAX_CHAR_COUNT,
    //   //   `Description must be ${MAX_CHAR_COUNT} characters or less`
    //   // )
    //   .required("Description is required"),
    bannerImage: Yup.mixed()
      .test(
        "fileSize",
        "File size is too large ,Size should be less then 4 MB",
        (value) => {
          if (value) {
            return value.size <= 1024 * 1024 * 4; // 4MB
          }
          return true;
        }
      )
      .required("Banner Image  is required")
      .test("fileFormat", "Unsupported file format", (value) => {
        if (value) {
          return ["image/jpg", "image/jpeg", "image/png"].includes(value.type);
        }
        return true;
      }),
    footerDescription: Yup.string().required("Footer Description is required"),
    termsAndConditions: Yup.string().required(
      "Terms and Conditions are required"
    ),
    headerColor: Yup.string()
      .matches(/^#([0-9A-F]{3}){1,2}$/i, "Invalid header color format")
      .required("Header color is required or Invalid color"),
    footerColor: Yup.string()
      .matches(/^#([0-9A-F]{3}){1,2}$/i, "Invalid footer color format")
      .required("Footer color is required or Invalid color"),

    // facebookUrl: Yup.string().url("Invalid URL format").notRequired(),
    // twitterUrl: Yup.string().url("Invalid URL format").notRequired(),
    // instaUrl: Yup.string().url("Invalid URL format").notRequired(),
    // tiktokUrl: Yup.string().url("Invalid URL format").notRequired(),
  });

  // Refs for input fields
  const mainTitleRef = useRef(null);
  const titleRef = useRef(null);
  const descriptionRef = useRef(null);
  const bannerImageRef = useRef(null);
  const footerDescriptionRef = useRef(null);
  const termsAndConditionsRef = useRef(null);
  const headerColorRef = useRef(null);

  const footerColorRef = useRef(null);

  const initialValues = {
    mainTitle: "",
    title: "",
    description: "",
    bannerImage: null,
    footerDescription: "",
    market: id,
    facebookUrl: " ",
    twitterUrl: " ",
    instaUrl: " ",
    tiktokUrl: " ",
    termsAndConditions: " ",
    headerColor: "",
    footerColor: "",
  };

  const [sections, setSections] = useState([]);
  const [bannerImageUrl, setBannerImageUrl] = useState(null);

  const [headerColor, setHeaderColor] = useState();
  const [footerColor, setFooterColor] = useState();

  const [descriptionError, setDescriptionError] = useState("");

  const handleBannerImageChange = (file) => {
    formik.setFieldValue("bannerImage", file);
    setBannerImageUrl(URL.createObjectURL(file));
  };

  const addSection = () => {
    setSections([...sections, { title: "", description: "" }]);
  };

  const removeSection = (index) => {
    const updatedSections = sections.filter((_, i) => i !== index);
    setSections(updatedSections);
  };

  const handleSectionChange = (index, updatedSection) => {
    const updatedSections = sections.map((section, i) =>
      i === index ? updatedSection : section
    );
    setSections(updatedSections);
  };

  const handleUploadImage = async (imageFile) => {
    try {
      const imageData = new FormData();
      imageData.append("image", imageFile);
      const result = await UploadSectionImageApi(imageData);
      if (!result.error) {
        return result.data.data.image;
      } else {
        console.error(result.message);
        return null;
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      return null;
    }
  };
  const onSubmit = async (values) => {
    console.warn("button clicked", values.bannerImage.size);
    // Validate image size manually

    const sectionsWithImageName = sections.map((section) => ({
      title: section.title,
      description: section.description,
      image: section.image || "",
    }));

    const socialMediaUrls = [
      "facebookUrl",
      "twitterUrl",
      "instaUrl",
      "tiktokUrl",
    ];
    socialMediaUrls.forEach((url) => {
      if (!values[url]) {
        values[url] = "N/A";
      }
    });

    const formData = {
      ...values,
      section: sectionsWithImageName,
      headerColor, // Include header color in the form data
      footerColor, // Include footer color in the form data
    };

    const data = await createSubAdminDetailApi(formData);
    if (!data.error) {
      navigate("/marketdate");
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    // enableReinitialize: true,
  });

  const handleCancel = () => {
    navigate("/marketdate");
  };
  const MAX_CHAR_COUNT = 256;

  const stripHtmlTags = (html) => {
    const div = document.createElement("div");
    div.innerHTML = html;
    return div.textContent || div.innerText || "";
  };

  // const handleDescriptionChange = (content) => {
  //   const textContent = stripHtmlTags(content);
  //   if (textContent.length > MAX_CHAR_COUNT) {
  //     setDescriptionError(
  //       `Description must be ${MAX_CHAR_COUNT} characters or less`
  //     );
  //     formik.setFieldValue(
  //       "description",
  //       textContent.substring(0, MAX_CHAR_COUNT)
  //     );
  //   } else {
  //     setDescriptionError("");
  //     formik.setFieldValue("description", content);
  //   }
  // };

  const handleDescriptionChange = (content) => {
    const textContent = stripHtmlTags(content);
  
    if (textContent.length > MAX_CHAR_COUNT) {
      // Update error message if character count exceeds the limit
      setDescriptionError(
        `Description must be ${MAX_CHAR_COUNT} characters or less`
      );
  
      // Create a temporary div to work with HTML content
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = content;
      
      // Truncate the content based on the plain text length
      let truncatedContent = '';
      let currentLength = 0;
  
      const traverseNodes = (node) => {
        if (currentLength >= MAX_CHAR_COUNT) return;
  
        if (node.nodeType === Node.TEXT_NODE) {
          const text = node.textContent;
          const remainingLength = MAX_CHAR_COUNT - currentLength;
  
          if (text.length > remainingLength) {
            truncatedContent += text.slice(0, remainingLength);
            currentLength += remainingLength;
            node.textContent = text.slice(0, remainingLength);
          } else {
            truncatedContent += text;
            currentLength += text.length;
          }
        } else if (node.nodeType === Node.ELEMENT_NODE) {
          const newElement = document.createElement(node.tagName);
          for (const attr of node.attributes) {
            newElement.setAttribute(attr.name, attr.value);
          }
          node.childNodes.forEach((child) => traverseNodes(child));
          if (newElement.innerHTML) {
            truncatedContent += newElement.outerHTML;
          }
        }
      };
  
      traverseNodes(tempDiv);
      formik.setFieldValue('description', truncatedContent);
  
    } else {
      // Clear error message and update form value
      setDescriptionError('');
      formik.setFieldValue('description', content);
    }
  };
  

  useEffect(() => {
    if (formik.errors.mainTitle && formik.touched.mainTitle) {
      mainTitleRef.current?.focus();
    } else if (formik.errors.title && formik.touched.title) {
      titleRef.current?.focus();
    } else if (formik.errors.description) {
      descriptionRef.current?.focus();
    } else if (formik.errors.bannerImage) {
      bannerImageRef.current?.focus();
    } else if (formik.errors.footerDescription) {
      footerDescriptionRef.current?.focus();
    } else if (formik.errors.termsAndConditions) {
      termsAndConditionsRef.current?.focus();
    } else if (formik.errors.headerColor) {
      headerColorRef.current?.focus();
    } else if (formik.errors.footerColor) {
      footerColorRef.current?.focus();
    }
  }, [formik.errors, formik.touched]);

  return (
    <>
      <div className="panel-header">
        <div className="page-inner py- mb-4">
          <div className="d-flex align-items-left align-items-md-center flex-column flex-md-row">
            <div>
              <h2 className="pb-2 fw-bold">Add Detail for the LandingPage</h2>
              <ul className="breadcrumbs">
                <li className="nav-home">
                  <Link to="/">
                    <i className="flaticon-home" />
                  </Link>
                </li>
                <li className="separator">
                  <i className="flaticon-right-arrow" />
                </li>
                <li className="nav-item">
                  <a href="#"> LandingPage</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="page-inner mt--5">
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-header">
                <div className="card-title">Add LandingPage Details</div>
              </div>
              <div className="card-body">
                <form onSubmit={formik.handleSubmit}>
                  <div className="col-md-12 ">
                    <div className="form-row">
                      <div className="form-group col-md-12">
                        <label htmlFor="mainTitle">Main Title</label>
                        <input
                          type="text"
                          className={`form-control ${
                            formik.errors.mainTitle && formik.touched.mainTitle
                              ? "is-invalid"
                              : ""
                          }`}
                          id="mainTitle"
                          name="mainTitle"
                          placeholder="Main Title"
                          ref={mainTitleRef}
                          value={formik.values.mainTitle}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          maxLength={100}
                        />
                        {formik.errors.mainTitle &&
                          formik.touched.mainTitle && (
                            <div style={{ color: "red" }} className="">
                              {formik.errors.mainTitle}
                            </div>
                          )}
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-12">
                        <label htmlFor="title">Title</label>
                        <input
                          type="text"
                          className={`form-control ${
                            formik.errors.title && formik.touched.title
                              ? "is-invalid"
                              : ""
                          }`}
                          id="title"
                          name="title"
                          placeholder="Title"
                          ref={titleRef}
                          value={formik.values.title}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          maxLength={100}
                        />
                        {formik.errors.title && formik.touched.title && (
                          <div style={{ color: "red" }} className="">
                            {formik.errors.title}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="form-group">
                      <label htmlFor="description">Description</label>
                      <SunEditor
                        name="description"
                        placeholder="Description"
                        setContents={formik.values.description}
                        onChange={handleDescriptionChange}
                        ref={descriptionRef}
                        setOptions={{
                          buttonList: [
                            [
                              "bold",
                              "underline",
                              "italic",
                              "strike",
                              "subscript",
                              "superscript",
                            ],
                            ["undo", "redo"],
                            ["font", "fontSize"],
                            ["fontColor", "hiliteColor"],
                            ["align", "list", "lineHeight"],
                            ["table", "horizontalRule", "link"],
                          ],
                          defaultTag: "div",
                          minHeight: "200px",
                          showPathLabel: false,
                          charCounterLabel: "Character Count:", // Custom label
                          maxCharCount: MAX_CHAR_COUNT, // Set max characters allowed
                        }}
                      />
                      {formik.errors.description &&
                        formik.touched.description && (
                          <div style={{ color: "red" }} className="">
                            {formik.errors.description}
                          </div>
                        )}
                    </div>
                    <div className="form-group">
                      <label htmlFor="bannerImage">Upload Banner Image</label>
                      <DragsAndDrop
                        heading="Upload Image"
                        inputName="Image"
                        aspect={2 / 1}
                        uploadFile={(x) => {
                          handleBannerImageChange(x);
                        }}
                      />
                      {formik.errors.bannerImage &&
                        formik.touched.bannerImage && (
                          <div style={{ color: "red" }} className="">
                            {formik.errors.bannerImage}
                          </div>
                        )}
                    </div>

                    <div className="form-group">
                      <label htmlFor="footerDescription">
                        Footer Description
                      </label>
                      <SunEditor
                        name="footerDescription"
                        placeholder="Footer Description"
                        setContents={formik.values.footerDescription}
                        ref={footerDescriptionRef}
                        onChange={(content) =>
                          formik.setFieldValue("footerDescription", content)
                        }
                        setOptions={{
                          buttonList: [
                            [
                              "bold",
                              "underline",
                              "italic",
                              "strike",
                              "subscript",
                              "superscript",
                            ],
                            ["undo", "redo"],
                            ["font", "fontSize"],
                            ["fontColor", "hiliteColor"],
                            ["align", "list", "lineHeight"],
                            ["table", "horizontalRule", "link"],
                          ],
                          defaultTag: "div",
                          minHeight: "300px",
                          showPathLabel: false,
                        }}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="termsAndConditions">
                        Terms and Conditions
                      </label>
                      <SunEditor
                        name="termsAndConditions"
                        placeholder="Terms and Conditions"
                        setContents={formik.values.termsAndConditions}
                        ref={termsAndConditionsRef}
                        onChange={(content) =>
                          formik.setFieldValue("termsAndConditions", content)
                        }
                        setOptions={{
                          buttonList: [
                            [
                              "bold",
                              "underline",
                              "italic",
                              "strike",
                              "subscript",
                              "superscript",
                            ],
                            ["undo", "redo"],
                            ["font", "fontSize"],
                            ["fontColor", "hiliteColor"],
                            ["align", "list", "lineHeight"],
                            ["table", "horizontalRule", "link"],
                          ],
                          defaultTag: "div",
                          minHeight: "300px",
                          showPathLabel: false,
                        }}
                      />
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <label htmlFor="facebookUrl">Facebook URL</label>
                        <input
                          type="text"
                          className={`form-control ${
                            formik.errors.facebookUrl &&
                            formik.touched.facebookUrl
                              ? "is-invalid"
                              : ""
                          }`}
                          id="facebookUrl"
                          name="facebookUrl"
                          placeholder="Facebook URL"
                          value={formik.values.facebookUrl}
                          onChange={formik.handleChange}
                          maxLength={256}
                        />
                        {formik.errors.facebookUrl &&
                          formik.touched.facebookUrl && (
                            <div className="">{formik.errors.facebookUrl}</div>
                          )}
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="instaUrl">Instagram URL</label>
                        <input
                          type="text"
                          className={`form-control ${
                            formik.errors.instaUrl && formik.touched.instaUrl
                              ? "is-invalid"
                              : ""
                          }`}
                          id="instaUrl"
                          name="instaUrl"
                          placeholder="Instagram URL"
                          value={formik.values.instaUrl}
                          onChange={formik.handleChange}
                          maxLength={256}
                        />
                        {formik.errors.instaUrl && formik.touched.instaUrl && (
                          <div className="">{formik.errors.instaUrl}</div>
                        )}
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="instaUrl">TikTok URL</label>
                        <input
                          type="text"
                          className={`form-control ${
                            formik.errors.tiktokUrl && formik.touched.tiktokUrl
                              ? "is-invalid"
                              : ""
                          }`}
                          id="tiktokUrl"
                          name="tiktokUrl"
                          placeholder="TikTok URL"
                          value={formik.values.tiktokUrl}
                          onChange={formik.handleChange}
                          maxLength={256}
                        />
                        {formik.errors.tiktokUrl &&
                          formik.touched.tiktokUrl && (
                            <div className="">{formik.errors.tiktokUrl}</div>
                          )}
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="twitterUrl">Twitter URL</label>
                        <input
                          type="text"
                          className={`form-control ${
                            formik.errors.twitterUrl &&
                            formik.touched.twitterUrl
                              ? "is-invalid"
                              : ""
                          }`}
                          id="twitterUrl"
                          name="twitterUrl"
                          placeholder="Twitter URL"
                          value={formik.values.twitterUrl}
                          onChange={formik.handleChange}
                          maxLength={256}
                        />
                        {formik.errors.twitterUrl &&
                          formik.touched.twitterUrl && (
                            <div className="">{formik.errors.twitterUrl}</div>
                          )}
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="form-group col-md-3">
                        <label htmlFor="headerColor">Header Color</label>

                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <HexColorPicker
                            color={headerColor}
                            onChange={(color) => {
                              setHeaderColor(color);
                              formik.setFieldValue("headerColor", color);
                              formik.setFieldTouched("headerColor", true);
                            }}
                            style={{
                              marginBottom: "10px",
                            }}
                          />
                          <input
                            type="text"
                            className="form-control mt-2"
                            id="headerColorInput"
                            value={headerColor}
                            onChange={(e) => setHeaderColor(e.target.value)}
                            maxLength={7}
                            ref={headerColorRef}
                          />
                          {formik.errors.headerColor &&
                            formik.touched.headerColor && (
                              <div style={{ color: "red" }} className="">
                                {formik.errors.headerColor}
                              </div>
                            )}

                          <div
                            style={{
                              width: "50px",
                              height: "50px",
                              backgroundColor: headerColor,
                              border: "1px solid #ccc",
                              borderRadius: "4px",
                              marginTop: "10px",
                            }}
                          />
                        </div>
                      </div>
                      <div className="form-group col-md-3">
                        <label htmlFor="footerColor">Footer Color</label>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <HexColorPicker
                            color={footerColor}
                            onChange={(color) => {
                              setFooterColor(color);
                              formik.setFieldValue("footerColor", color);
                              formik.setFieldTouched("footerColor", true);
                            }}
                            style={{
                              marginBottom: "10px",
                            }}
                          />
                          <input
                            type="text"
                            className="form-control mt-2"
                            id="footerColorInput"
                            value={footerColor}
                            onChange={(e) => setFooterColor(e.target.value)}
                            maxLength={7} // # followed by 6 hex digits
                            ref={footerColorRef}
                          />
                          {formik.errors.footerColor &&
                            formik.touched.footerColor && (
                              <div style={{ color: "red" }}>
                                {formik.errors.footerColor}
                              </div>
                            )}
                          <div
                            style={{
                              width: "50px",
                              height: "50px",
                              backgroundColor: footerColor,
                              border: "1px solid #ccc",
                              borderRadius: "4px",
                              marginTop: "10px",
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="form-group">
                      {sections.map((section, index) => (
                        <SectionInput
                          key={index}
                          index={index}
                          section={section}
                          onChange={handleSectionChange}
                          onRemove={removeSection}
                          handleUploadImage={handleUploadImage}
                        />
                      ))}
                      <button
                        type="button"
                        className="btn btn-primary mt-4"
                        onClick={addSection}
                      >
                        Add Section
                      </button>
                    </div>
                  </div>

                  <button type="submit" className="btn btn-primary mt-4">
                    Submit
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary mt-4 ml-2"
                    onClick={handleCancel}
                  >
                    Cancel
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddSubAdminLandingPageDetail;
