import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import "./emailmodal.css"; // Make sure to import your CSS file
import useApiAxios from "../../api/useApiAxios.js";

const EmailModal = ({ show, handleClose, selectedVendorIds, initialTitle, initialBody, logId }) => {
  const [title, setTitle] = useState(initialTitle || "");
  const [emailBody, setEmailBody] = useState(initialBody || ""); // Assuming initialBody is in HTML format
  const { sendMultiEmailApi, reSendEmailApi } = useApiAxios();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (initialTitle) {
      setTitle(initialTitle);
    }
    if (initialBody) {
      setEmailBody(initialBody); // No need to strip HTML tags if initialBody is in HTML format
    }
  }, [initialTitle, initialBody]);

  const handleSubmit = async () => {
    try {
      setIsLoading(true);

      const emailData = {
        body: emailBody, // Assuming initialBody is already in HTML format
        title,
        logId, // Include logId in the email data
      };

      let response;
      if (logId) {
        // If logId is present, use reSendEmailApi and omit vendorIds
        response = await reSendEmailApi(emailData);
      } else {
        // Otherwise, use sendMultiEmailApi including vendorIds
        emailData.vendorIds = selectedVendorIds;
        response = await sendMultiEmailApi(emailData);
      }

      setIsLoading(false);
      console.log("Email sent successfully:", response.data);
      handleClose();
    } catch (error) {
      setIsLoading(false);
      console.error("Error sending email:", error);
    }
  };

  return (
    <div>
      <Modal show={show} onHide={handleClose} dialogClassName="custom-modal">
        <Modal.Header>
          <Modal.Title>Send Email</Modal.Title>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" onClick={handleClose}>
          <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label htmlFor="title">Title:</label>
            <input
              type="text"
              className="form-control"
              id="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
          <div className="form-group mt-3">
            <label htmlFor="emailBody">Email Body:</label>
            <SunEditor
              id="emailBody"
              width="100%"
              height="300px"
              value={emailBody}
              onChange={(content) => setEmailBody(content)}
              setOptions={{
                buttonList: [
                  [
                    "bold",
                    "underline",
                    "italic",
                    "strike",
                    "subscript",
                    "superscript",
                  ],
                  ["undo", "redo"],
                  ["font", "fontSize"],
                  ["fontColor", "hiliteColor"],
                  ["align", "list", "lineHeight"],
                  ["table", "horizontalRule", "link"],
                ],
                defaultTag: "div",
                minHeight: "300px",
                showPathLabel: false,
              }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button onClick={handleClose} className="btn btn-secondary">
            Close
          </button>
          <button onClick={handleSubmit} className="btn btn-primary" disabled={isLoading}>
            {isLoading ? "Sending..." : "Send Email"}
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default EmailModal;
