import React, { useContext, useEffect, useState } from "react";
import useApiAxios from "../../api/useApiAxios";
import EmailModal from "../Modal/emailSectionModal"; // Ensure correct import path
import { Link } from "react-router-dom";
import Footer from "../footer/Footer";
import { Tooltip } from "react-tooltip";
import ReactPaginate from "react-paginate"; // Import ReactPaginate


const EmailLogs = () => {
  const { getEmailLogs } = useApiAxios();
  const [emailLogs, setEmailLogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [showModal, setShowModal] = useState(false);
  const [selectedLog, setSelectedLog] = useState(null);
  
  const [currentPage, setCurrentPage] = useState(0);
  const [emailPerPage, setEmailPerPage] = useState(10);
  const emailPerPageOptions = [10, 20, 50 ];


  useEffect(() => {
    const fetchEmailLogs = async () => {
      try {
        const response = await getEmailLogs();
        setEmailLogs(response.data.data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchEmailLogs();
  }, []);

  const handleResendClick = (log) => {
    setSelectedLog(log);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedLog(null);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };


  const indexOfLastVendor = (currentPage + 1) * emailPerPage;
  const indexOfFirstVendor = indexOfLastVendor - emailPerPage;
  const currentEmails = emailLogs.slice(
    indexOfFirstVendor,
    indexOfLastVendor
  );

  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
  };

  const handleEmailPerPageChange = (event) => {
    setEmailPerPage(Number(event.target.value));
    setCurrentPage(0); // Reset to the first page when changing the number of vendors per page
  };

  return (
    <>
      <div className="panel-header">
        <div className="page-inner py-5">
          <div className="d-flex align-items-left align-items-md-center flex-column flex-md-row">
            <div>
              <h2 className="pb-2 fw-bold">Email Logs</h2>
              <ul className="breadcrumbs">
                <li className="nav-home">
                  <Link to="/">
                    <i className="flaticon-home" />
                  </Link>
                </li>
                <li className="separator">
                  <i className="flaticon-right-arrow" />
                </li>
                <li className="nav-item">
                  <a href="#">Email Logs</a>
                </li>
                <li className="separator">
                  <i className="flaticon-right-arrow" />
                </li>
                <li className="nav-item">
                  <a href="#">Email Logs</a>
                </li>
              </ul>
            </div>
            <div className="ml-md-auto py-2 py-md-0">
              <div className="row">
                <div className="col-6">
                  <div className="row"></div>
                </div>
                <div className="col-6">
                  <div className="row"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page-inner mt--5">
        <div className="row">
          <div className="col-md-12">
            <div className="card">
            <div className="card-body">

              <div className="table-responsive">
                <table
                  id="add-row"
                  className="display table table-striped table-hover"
                >
                  <thead>
                    <tr>
                      <th>Email Subject</th>
                      <th>Recipient</th>
                      <th>Sent At</th>
                      <th>Last Modified</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentEmails.map((log) => (
                      <tr key={log._id}>
                        <td>{log.title}</td>
                        <td>{log.vendorIds.length}</td>
                        <td>{formatDate(log.sentAt)}</td>
                        <td>{formatDate(log.updatedAt)}</td>
                        <td>
                          <button
                            className="btn btn-primary btn-round"
                            onClick={() => handleResendClick(log)}
                          >
                            Resend
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="d-flex justify-content-between align-items-center pagination-container">
                    <ReactPaginate
                      previousLabel={"previous"}
                      nextLabel={"next"}
                      breakLabel={"..."}
                      pageCount={Math.ceil(emailLogs.length / emailPerPage)}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"active"}
                    />
                    <div className="form-group d-flex align-items-center items-per-page">
                    <label htmlFor="vendorsPerPage">Vendors per page: </label>

                      <select
                        value={emailPerPage}
                        onChange={handleEmailPerPageChange}
                        className="form-control m-2"
                        style={{ width: "auto", display: "inline-block" }}

                      >
                        {emailPerPageOptions.map((option) => (
                          <option key={option} value={option}>
                            Show {option}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showModal && (
        <EmailModal
          show={showModal}
          handleClose={handleCloseModal}
          selectedVendorIds={selectedLog.vendorIds}
          initialTitle={selectedLog.title}
          initialBody={selectedLog.body}
          logId={selectedLog._id} // Pass logId to the EmailModal component
        />
      )}
    </>
  );
};

export default EmailLogs;
