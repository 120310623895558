import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Calendar, Views, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Link } from "react-router-dom";
import useApiAxios from "../../api/useApiAxios";
import MapModal from "./MapModal";

// Setup the moment localizer
const localizer = momentLocalizer(moment);

const MapMapping = () => {
  const { getAllMarket } = useApiAxios();
  const [adminData, setAdminData] = useState([]);
  const [markets, setMarkets] = useState([]);
  const [selectedMarket, setSelectedMarket] = useState("");
  const [selectedMarketData, setSelectedMarketData] = useState(null);
  const [currentDate, setCurrentDate] = useState(null);
  const [OpenModal, setOpenModal] = useState(false);

  const fetchData = async () => {
    const res = await getAllMarket();
    if (!res.error) {
      setAdminData(res.data.data);
      setMarkets(res.data.data);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  const [myEvents, setEvents] = useState([]);
  const handleMarketChange = (e) => {
    const market = markets.find((market) => market.name === e.target.value);
    if (market) {
      setSelectedMarketData(market);
      console.log(market);
      const event = [];
      for (let index = 0; index < market?.marketDate.length; index++) {
        const element = market?.marketDate[index];
        event.push({
          id: element._id,
          start: new Date(element.date),
          end: new Date(element.date),
          allDay: true,
          title: "Market Day",
        });
      }
      setEvents(event);
    } else {
      setSelectedMarketData(null);
      setEvents([]);
    }
  };
  const handleCloseModal = () => {
    setCurrentDate(null);
    setOpenModal(false);
    setSelectedMarketData(null);
    setEvents([]);
  };
  const handleSelectEvent = (e) => {
    const date = selectedMarketData.marketDate.find(
      (market) => market._id === e.id
    );
    setCurrentDate({ ...date, marketMapImage: selectedMarketData?.mapImage });
    setOpenModal(true);
  };

  return (
    <div>
      <div className="panel-header">
        <div className="page-inner py-5">
          <div className="d-flex align-items-left align-items-md-center flex-column flex-md-row">
            <div>
              <h2 className="pb-2 fw-bold">Map</h2>
              <ul className="breadcrumbs">
                <li className="nav-home">
                  <Link to="/">
                    <i className="flaticon-home" />
                  </Link>
                </li>
                <li className="separator">
                  <i className="flaticon-right-arrow" />
                </li>
                <li className="nav-item">
                  <a href="#">Map</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="form-group">
        <label htmlFor="marketSelect">Select Market</label>
        <select
          className="form-control"
          id="marketSelect"
          onChange={handleMarketChange}
        >
          <option value="">Select a market</option>
          {markets.map((market, index) => (
            <option key={index} value={market.name}>
              {market.name}
            </option>
          ))}
        </select>
      </div>
      {myEvents.length !== 0 ? (
        <div style={{ height: "600px", width: "80vw" }} className="height600">
          <Calendar
            localizer={localizer}
            defaultDate={new Date()}
            defaultView={Views.MONTH}
            view={Views.MONTH}
            events={myEvents}
            onSelectEvent={handleSelectEvent}
            scrollToTime={new Date()}
            components={{
              toolbar: CustomToolbar,
            }}
          />
        </div>
      ) : selectedMarketData ? (
        <p>No Date found</p>
      ) : (
        <></>
      )}
      <MapModal
        show={OpenModal}
        handleClose={handleCloseModal}
        date={currentDate}
      />
    </div>
  );
};

const CustomToolbar = (toolbar) => {
  return (
    <div className="rbc-toolbar">
      <span className="rbc-toolbar-label">{`${toolbar.label}`}</span>
      <span className="rbc-btn-group">
        <button onClick={() => toolbar.onNavigate("PREV")}>Back </button>
        <button onClick={() => toolbar.onNavigate("TODAY")}>Today</button>
        <button onClick={() => toolbar.onNavigate("NEXT")}>Next</button>
      </span>
    </div>
  );
};

export default MapMapping;
