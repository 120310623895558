import React, { useEffect, useState } from "react";
import useApiAxios from "../../api/useApiAxios";
import { Link } from "react-router-dom";
import moment from "moment"; // Import moment

const VendorPaymentList = () => {
  const { getAllInvoice, getAllVendor, searchgetInvoiceSearchHistoryApi } =
    useApiAxios();
  const [payments, setPayments] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const fetchData = async (page = 1 ,limit = 1000) => {
    try {
      const vendorResponse = await getAllVendor();
      setVendors(vendorResponse.data.data);

      const invoiceResponse = await getAllInvoice(page,limit);
      setPayments(invoiceResponse.data.data);
      setTotalPages(invoiceResponse.data.data.totalPages);

      setLoading(false);
    } catch (error) {
      setError("Error fetching data");
      setLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage]);

  const handleSearch = async (event) => {
    try {
      const query = event.target.value;
      setSearchQuery(query);
      const response = await searchgetInvoiceSearchHistoryApi(query);
      setPayments(response.data.data);
    } catch (error) {
      console.error("Error searching:", error);
    }
  };

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };
  return (
    <div>
      <div className="panel-header">
        <div className="page-inner py-5">
          <div className="d-flex align-items-left align-items-md-center flex-column flex-md-row">
            <div>
              <h2 className="pb-2 fw-bold">Vendor Invoice Payment List</h2>
            </div>
          </div>
          {/* <div className="ml-md-auto py-2 py-md-0">
            <div className="row">
              <div className="col">
                <div className="row">
                  <div class="collapse" id="search-nav">
                    <form class="navbar-left navbar-form nav-search mr-md-3">
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <button
                            type="submit"
                            class="btn btn-search pr-1"
                            onClick={handleSearch}
                          >
                            <i class="fa fa-search search-icon"></i>
                          </button>
                        </div>
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Search ..."
                          value={searchQuery}
                          onChange={handleSearch}
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>

      <div className="page-inner mt--5">
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Sub Admin Name</th>
                      <th>Sub Admin Email</th>
                      <th>Vendor Name</th>
                      <th>Price</th>
                      <th>Date</th>
                      <th>Payment Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {payments.length > 0 ? (
                      payments.map((payment) => {
                        return (
                          <tr key={payment._id}>
                            <td>{payment?.subAdmin?.name}</td>
                            <td>{payment?.subAdmin?.email}</td>
                            <td>{payment?.vendorId?.businessName}</td>

                            <td>${payment?.price}</td>

                            <td>
                              {moment(
                                payment?.vendorDates[0]?.dateId?.date
                              ).format("MM/DD/YYYY")}
                            </td>
                            <td>{payment.status}</td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan="6">No payments found</td>
                      </tr>
                    )}
                  </tbody>
                </table>
                {/* <div className="pagination">
                  <button
                    className="btn btn-primary"
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                  >
                    Previous
                  </button>
                  <span>{`Page ${currentPage} of ${totalPages}`}</span>
                  <button
                    className="btn btn-primary"
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                  >
                    Next
                  </button>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VendorPaymentList;
