import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import useApiAxios from "../../api/useApiAxios";
import SunEditor from "suneditor-react";
import DragsAndDrop from "../../image/DragsAndDrop";
import { Link, useNavigate } from "react-router-dom";

const MapModal = ({ show, handleClose, date }) => {
  console.log(date);
  const navigate = useNavigate();
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title>Market Map</Modal.Title>

        <button
          type="button"
          class="close"
          data-dismiss="modal"
          style={{ fontSize: "30px" }}
          aria-label="Close"
          onClick={handleClose}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </Modal.Header>
      <Modal.Body>
        <div>
          <img
            width={"100%"}
            src={`${process.env.REACT_APP_IMG_URL}/${
              date?.mapImages ? date?.mapImages : date?.marketMapImage
            }`}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button
          variant="primary"
          onClick={() => navigate(`/map/canvas/${date?._id}`)}
        >
          {date?.mapImages ? "Edit Map" : "Create Map"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default MapModal;
