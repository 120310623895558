

import React, { useContext, useEffect } from 'react';
import { useSearchParams, Link } from 'react-router-dom';
import { Context } from "../../context/Context";
import useApiAxios from "../../api/useApiAxios";
import "./successpage.css";

const VendorSuccess = () => {
    const {updateVendorPaymentSubscriptionApi } = useApiAxios();
    const [searchParams] = useSearchParams();
    const id = searchParams.get("session_id");
    // console.warn("User Data:", userData);
    const update = async () => {
        const   response = await updateVendorPaymentSubscriptionApi(id);
      };
    useEffect(() => {
       

        update();
    }, []);

    return (
        <div>
          
                <div className="wrapper-1">
                    <div className="wrapper-2">
                        <h1>Thank you!</h1>

                            <>
                                <p>Dear vendor you payment done ,</p>
                                <p>Thank you for choosing our platform to create and manage your market. We are thrilled to have you on board!</p>
                                <p>Your decision to partner with us is greatly appreciated. Our goal is to provide you with the best tools and support to help your market thrive and succeed.</p>
                                <p>You have successfully subscribed to our services, and we are committed to offering you a seamless and productive experience.</p>
                                <p>If you have any questions or need assistance, our support team is always here to help.</p>
                                <p>Once again, thank you for your trust and for choosing our platform. We look forward to a successful partnership!</p>
                            </>
                      <div className=" mt-4">

                   
                      </div>
                    </div>
                </div>
          
        </div>
    );
};

export default VendorSuccess;
