import React from "react";
import moment from "moment";

const ViewDateDetail = () => {
  const handleApprove = (item) => {
    // Implement your approval logic
  };

  const handleReject = (item) => {
    // Implement your rejection logic
  };

  const dummyData = [
    {
      date: "2024-08-01",
      name: "Event 1",
      email: "vendor01@gmail.com",
      contactPerson: "event 1",
    },
    {
      date: "2024-08-15",
      name: "Event 2",
      email: "vendor2@gmail.com",
      contactPerson: "event 2",
    },
  ];

  return (
    <div className="panel-header">
      <div className="page-inner py-5">
        <h2 className="pb-2 fw-bold">Details</h2>
        <style>
          {`
          .btn-orange {
            background-color: #ff5c00; /* Orange */
            border-color: #FFA500;
            color: white;
          }

          .btn-orange:hover {
            background-color: #FF8C00; /* Dark Orange */
            border-color: #FF8C00;
          }
        `}
        </style>

        <table className="table">
          <thead>
            <tr>
              <th>Date</th>
              <th>Business Name</th>
              <th>Email</th>
              <th>Contact Person</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {dummyData.map((item, index) => (
              <tr key={index}>
                <td>{moment(item.date).format("MMMM Do YYYY")}</td>
                <td>{item.name}</td>
                <td>{item.email}</td>
                <td>{item.contactPerson}</td>
                <td>
                  <button
                    type="button"
                    onClick={() => handleApprove(item)}
                    className="btn btn-primary m-2"
                  >
                    Approve
                  </button>
                  <button
                    type="button"
                    onClick={() => handleReject(item)}
                    className="btn btn-orange"
                  >
                    Reject
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>{" "}
    </div>
  );
};

export default ViewDateDetail;
