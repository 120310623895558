import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import useApiAxios from "../../api/useApiAxios";
import { useParams } from "react-router-dom";
import SunEditor from "suneditor-react";
import DragsAndDrop from "../../image/DragsAndDrop";

const AddSectionModal = ({
  show,
  handleClose,
  handleAddSection,
  marketContentId,
}) => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null); // State to store image preview
  const { addSectionApi } = useApiAxios();
  const imageUrl = process.env.REACT_APP_IMG_URL;

  useEffect(() => {
    // Reset form fields on modal show
    setTitle("");
    setDescription("");
    setImage(null);
    setImagePreview(null);
  }, [show]);

  const handleImageChange = (file) => {
    // const file = event.target.files[0];
    setImage(file);
    // Set image preview
    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreview(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleSaveChanges = async () => {
    try {
      const formData = new FormData();
      formData.append("title", title);
      formData.append("description", description);
      if (image) {
        formData.append("image", image);
      }

      const result = await addSectionApi(marketContentId, formData);

      if (result.success && result.status === 201) {
        handleAddSection(result.data);
        handleClose();
      } else {
        console.error("Failed to add section:", result.error);
        handleClose();
      }
    } catch (error) {
      console.error("Error adding section:", error);
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title>Add Section</Modal.Title>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          style={{ fontSize: "30px" }}
          aria-label="Close"
          onClick={handleClose}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </Modal.Header>
      <Modal.Body>
        <form>
          <div className="mb-3">
            <label htmlFor="addTitle" className="form-label">
              Title
            </label>
            <input
              type="text"
              className="form-control"
              id="addTitle"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="addDescription" className="form-label">
              Description
            </label>
            <SunEditor
              setOptions={{
                buttonList: [
                  ["undo", "redo"],
                  ["font", "fontSize", "formatBlock"],
                  [
                    "bold",
                    "underline",
                    "italic",
                    "strike",
                    "subscript",
                    "superscript",
                  ],
                  ["fontColor", "hiliteColor"],
                  ["indent", "outdent"],
                  ["align", "horizontalRule", "list", "table"],
                  ["link", "image", "video"],
                  ["fullScreen", "showBlocks", "codeView"],
                ],
              }}
              height="250px"
              id="addDescription"
              onChange={setDescription}
              setContents={description}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="newImage" className="form-label">
              Select Image (Optional)
            </label>
            
            <DragsAndDrop
              heading="Upload Image"
              inputName="Image"
              aspect={2 / 2}
              uploadFile={(x) => {
                handleImageChange(x);
              }}
            />
          </div>
          {/* Display image preview */}
          
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button
          variant="primary"
          onClick={handleSaveChanges}
          disabled={!title || !description}
        >
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddSectionModal;
