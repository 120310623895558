// import React, { useContext, useEffect, useState } from "react";
// import { Context } from "../../context/Context";
// import useApiAxios from '../../api/useApiAxios';

// const Profile = () => {
//     const { updateProfileApi } = useApiAxios();
//     const { userData } = useContext(Context);
//     console.warn("userData",userData)




//     const [userInfo, setUserInfo] = useState({
//         name: "",
//         organization: "",
//         phone: "",
//         about: "",
//         password: "",
//     });
//     useEffect(() => {
//         if (userData) {
//             setUserInfo({
//                 name: userData.name,
//                 organization: userData.organization,
//                 email: userData.email,
//                 phone: userData.phone,
//                 about: userData.about,
//             });
//         }
//     }, [userData]);
//     const handleChange = (e) => {
//         const { name, value } = e.target;
//         setUserInfo(prevState => ({
//             ...prevState,
//             [name]: value
//         }));
//     };
//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         try {
//             const response = await updateProfileApi(userData._id, userInfo);

//         } catch (error) {
//             // Handle error
//             console.error("Error updating profile:", error);
//             alert("Failed to update profile. Please try again.");
//         }
//     };
//     return (
//         <div className='main-panel'>
//             <div className="content">
//                 <div className="panel-header">
//                     <div className="page-inner py-5">
//                         <div className="d-flex align-items-left align-items-md-center flex-column flex-md-row">
//                             <div>
//                                 <h2 className="pb-2 fw-bold">My Profile</h2>
//                                 <ul className="breadcrumbs">
//                                     <li className="nav-home">
//                                         <a href="#">
//                                             <i className="flaticon-home" />
//                                         </a>
//                                     </li>
//                                     <li className="separator">
//                                         <i className="flaticon-right-arrow" />
//                                     </li>
//                                     <li className="nav-item">
//                                         <a href="#">My Account</a>
//                                     </li>
//                                     <li className="separator">
//                                         <i className="flaticon-right-arrow" />
//                                     </li>
//                                     <li className="nav-item">
//                                         <a href="#">My Profile</a>
//                                     </li>
//                                 </ul>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//                 <div className="page-inner mt--5">
//                     <div className="col-md-8 col-xl-9">
//                         <div className="card">
//                             <div className="card-header">
//                                 <h5 className="card-title mb-0">Public info</h5>
//                             </div>
//                             <div className="card-body">
//                                 <form onSubmit={handleSubmit}>
//                                     <div className="row">
//                                         <div className="col-md-8">
//                                             <div className="form-group">
//                                                 <label htmlFor="inputUsername">Username</label>
//                                                 <input
//                                                     type="text"
//                                                     className="form-control"
//                                                     id="name"
//                                                     name="name"
//                                                     placeholder="Name"
//                                                     value={userInfo.name}
//                                                     onChange={handleChange}
//                                                 />
//                                             </div>
//                                             <div className="form-group">
//                                                 <label htmlFor="inputUsername">Email</label>
//                                                 <input
//                                                     type="text"
//                                                     className="form-control"
//                                                     id="email"
//                                                     name="email"
//                                                     placeholder="email"
//                                                     value={userInfo.email}
//                                                     onChange={handleChange}
//                                                     readOnly
//                                                 />
//                                             </div>
//                                             <div className="form-group">
//                                                 <label htmlFor="inputUsername">Organization</label>
//                                                 <input
//                                                     type="text"
//                                                     className="form-control"
//                                                     id="organization"
//                                                     name="organization"
//                                                     placeholder="Organization"
//                                                     value={userInfo.organization}
//                                                     onChange={handleChange}
//                                                 />
//                                             </div>
//                                             <div className="form-group">
//                                                 <label htmlFor="about">About</label>
//                                                 <textarea
//                                                     className="form-control"
//                                                     id="about"
//                                                     name="about"
//                                                     placeholder="About"
//                                                     value={userInfo.about}
//                                                     onChange={handleChange}
//                                                 />
//                                             </div>

//                                             <div className="form-group">
//                                                 <label htmlFor="phone">Contact Number</label>
//                                                 <input
//                                                     type="text"
//                                                     value={userInfo.phone}
//                                                     onChange={handleChange}
//                                                     name="phone"
//                                                     className="form-control"
//                                                     id="phone"
//                                                     placeholder="Contact Number"
//                                                 />
//                                             </div>


//                                             <div className="form-group">
//                                                 <label htmlFor="inputNewPassword">Password</label>
//                                                 <input
//                                                     type="password"
//                                                     className="form-control"
//                                                     id="password"
//                                                     name="password"
//                                                     placeholder="Password"
//                                                     value={userInfo.password}
//                                                     onChange={handleChange}
//                                                 />
//                                             </div>
//                                         </div>
//                                         <div className="col-md-4">
//                                             <div className="text-center">
//                                                 <div className="avatar avatar-xxl">
//                                                     <img
//                                                         src="assets/img/jm_denis.jpg"
//                                                         alt="..."
//                                                         className="avatar-img rounded-circle"
//                                                     />
//                                                 </div>
//                                                 <div className="mt-2">
//                                                     <span className="btn btn-primary">
//                                                         <i className="fas fa-upload" /> Upload
//                                                     </span>
//                                                 </div>
//                                                 <small>
//                                                     For best results, use an image at least 128px by 128px in
//                                                     .jpg format
//                                                 </small>
//                                             </div>
//                                         </div>
//                                     </div>
//                                     <button type="submit" className="btn btn-primary">
//                                         Save changes
//                                     </button>
//                                 </form>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     )
// }

// export default Profile


import React, { useContext, useEffect, useState } from "react";
import { Context } from "../../context/Context";
import useApiAxios from '../../api/useApiAxios';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Link } from "react-router-dom";

const Profile = () => {
    const { updateProfileApi } = useApiAxios();
    const { userData } = useContext(Context);
    const [showPassword, setShowPassword] = useState(false);
    // console.warn("PPPPPPPPPPPPPPPPPPP",userData)
    const [userInfo, setUserInfo] = useState({
        name: userData.name,
        organization: userData.organization,
        phone: userData.phone,
        email: userData.email,
        about: userData.about,
        password: "",
    });

    useEffect(() => {
        if (userData) {
            setUserInfo({
                name: userData.name,
                organization: userData.organization,
                email: userData.email,
                phone: userData.phone,
                about: userData.about,
            });
        }
    }, [userData]);

    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            setSubmitting(true);
            const response = await updateProfileApi(userData._id, values);
            // Handle success
        } catch (error) {
            // Handle error
            console.error("Error updating profile:", error);
            alert("Failed to update profile. Please try again.");
        } finally {
            setSubmitting(false);
        }
    };
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <>
           
                <div className="panel-header">
                    <div className="page-inner py-5">
                        <div className="d-flex align-items-left align-items-md-center flex-column flex-md-row">
                            <div>
                                <h2 className="pb-2 fw-bold">My Profile</h2>
                                <ul className="breadcrumbs">
                                    <li className="nav-home">
                                    <Link to ="/">
                      <i className="flaticon-home" />
                    </Link>
                                    </li>
                                  
                                   
                                    <li className="separator">
                                        <i className="flaticon-right-arrow" />
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/profile">My Profile</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page-inner mt--5">
                    <div className="col-md-8 col-xl-9">
                        <div className="card">
                            <div className="card-header">
                                <h5 className="card-title mb-0">Public info</h5>
                            </div>
                            <div className="card-body">
                                <Formik
                                    initialValues={userInfo}
                                    onSubmit={handleSubmit}
                                    validationSchema={Yup.object({
                                        name: Yup.string(),
                                        organization: Yup.string(),
                                        about: Yup.string(),
                                        phone: Yup.string()
                                            .matches(/^[0-9]+$/, 'Phone number must contain only numeric digits')
                                            .min(10, 'Phone number must be exactly 10 digits')
                                            .max(10, 'Phone number must be exactly 10 digits'),
                                        password: Yup.string().min(6, 'Password must be at least 6 characters'),
                                    })}
                                >
                                    <Form>
                                        <div className="row">
                                            <div className="col-md-8">
                                                <div className="form-group">
                                                    <label htmlFor="inputUsername">Username</label>
                                                    <Field
                                                        type="text"
                                                        className="form-control"
                                                        id="name"
                                                        name="name"
                                                        placeholder="Name"
                                                    />
                                                    <ErrorMessage name="name" component="div" className="text-danger" />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="inputUsername">Email</label>
                                                    <Field
                                                        type="email"
                                                        className="form-control"
                                                        id="email"
                                                        name="email"
                                                        placeholder="Email"
                                                        readOnly
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="inputUsername">Organization</label>
                                                    <Field
                                                        type="text"
                                                        className="form-control"
                                                        id="organization"
                                                        name="organization"
                                                        placeholder="Organization"
                                                    />
                                                    <ErrorMessage name="organization" component="div" className="text-danger" />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="about">About</label>
                                                    <Field
                                                        as="textarea"
                                                        className="form-control"
                                                        id="about"
                                                        name="about"
                                                        placeholder="About"
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="phone">Contact Number</label>
                                                    <Field
                                                        type="text"
                                                        className="form-control"
                                                        id="phone"
                                                        name="phone"
                                                        placeholder="Contact Number"
                                                    />
                                                    <ErrorMessage name="phone" component="div" className="text-danger" />
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="inputNewPassword">Password</label>
                                                    <div className="input-group">
                                                        <Field
                                                            type={showPassword ? "text" : "password"}
                                                            className="form-control"
                                                            id="password"
                                                            name="password"
                                                            placeholder="Password"
                                                        />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text">
                                                                <i
                                                                    className={`toggle-password ${showPassword ? "fa fa-eye" : "fa fa-eye-slash"}`}
                                                                    onClick={togglePasswordVisibility}
                                                                    style={{ cursor: "pointer" }}
                                                                ></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <ErrorMessage name="password" component="div" className="text-danger" />
                                                </div>

                                            </div>
                                           
                                        </div>
                                        <button type="submit" className="btn btn-primary">
                                            Save changes
                                        </button>
                                    </Form>
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
           
        </>
    )
}

export default Profile;
