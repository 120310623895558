import React from 'react'
import { Link } from 'react-router-dom'
import "./footer.css"
const Footer = () => {

  const getCurrentYear = () => {
    return new Date().getFullYear();
  };

  
  return (
   
    
  <footer className="footer">
    <div className="container-fluid">
      <nav className="pull-left">
        <ul className="nav">
          <li className="nav-item">
            <a className="nav-link" href="#">
              Help
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#">
              Licenses
            </a>
          </li>
        </ul>
      </nav>
      <div className="copyright ml-auto">
      {getCurrentYear()} © <Link to="/">Eventease360</Link>
      </div>
    </div>
  </footer>


  
  )
}

export default Footer
