import React, { useContext } from "react";
import { Container, Card, Row, Col, Image } from "react-bootstrap";
import { Context } from "../../context/Context";

const PendingStatus = () => {
    const { userData } = useContext(Context);
    const imgUrl = process.env.REACT_APP_IMG_URL;

    return (
        <Container className="d-flex justify-content-center align-items-center" style={{ minHeight: "100vh", backgroundColor: "#f8f9fa" }}>
            <Card className="text-center shadow" style={{ padding: "20px", width: "100%", maxWidth: "600px" }}>
                <Card.Body>
                    <Image 
                        src={`${imgUrl}/${userData.vendor.productImage1}`} 
                        roundedCircle 
                        style={{ width: "100px", height: "100px", objectFit: "cover" }} 
                        className="mb-4" 
                    />
                    <Row className="mb-3">
                        <Col>
                            <h2>Hello, {userData.vendor.businessName}!</h2>
                        </Col>
                    </Row>
                    <Card.Title className="mb-3" style={{ color: "#dc3545" }}>Status is Pending</Card.Title>
                    <Card.Text className="mb-4">
                        Your approval status is currently pending. Please wait for further updates from the market owner.
                    </Card.Text>
                  
                  
                  
                </Card.Body>
            </Card>
        </Container>
    );
};

export default PendingStatus;
