import { Outlet } from "react-router-dom";
import { useState } from "react";
import Header from "../header/Header";
import SideBar from "../sidebar/SideBar";
// import jsonData from "../../data/sideBar.json";
// import jsonData1 from "../../data/sideBar1.json";
import { Context } from "../../context/Context";
import Footer from "../footer/Footer";

const Backend = () => {
  const [sideBarEnable, setSideBarEnable] = useState(true);

  return (
    <>
      <div
        data-sidebar="light"
        data-layout-mode="light"
        className={sideBarEnable ? "sidebar-enable" : "vertical-collpsed"}
      >
        <div id="layout-wrapper">
          <Header
            sideBarEnable={sideBarEnable}
            setSideBarEnable={setSideBarEnable}
          />
          <SideBar />
          <div className="container-fluid">
            <div className="wrapper">
              <div className="main-panel">
                <div className="content">
                  <Outlet />
                </div>
                <Footer />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Backend;
