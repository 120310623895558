import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { useFormik } from "formik";
import useApiAxios from "../../api/useApiAxios";
import * as Yup from "yup";
import Footer from "../footer/Footer";

const EditCategory = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const { updateCategoryApi } = useApiAxios();
  const navigate = useNavigate();
  const user = location.state?.user;
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    unit_amount: Yup.number()
      .typeError("Must be a number")
      .min(1, "The price must be at least 1")
      .required("Category Price is required"),
  });
  const initialValues = {
    name: user ? user.name : "",

    unit_amount: user ? user.priceModel.unit_amount : "",
  };
  useEffect(() => {}, [user]);
  const onSubmit = async (values, { setSubmitting }) => {
    try {
      const result = await updateCategoryApi(user._id, values);
  
      if (result.error) {
        console.error("Error updating category:", result.data);
      } else {
        navigate("/viewcategory");
      }
    } catch (error) {
      console.error("Unexpected error:", error.message || error);
    } finally {
      setSubmitting(false);
    }
  };
  
  
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  const handleKeyDown = (e) => {
    if (
      (e.keyCode < 48 || e.keyCode > 57) &&
      e.keyCode !== 8 &&
      e.keyCode !== 46 &&
      (e.keyCode < 37 || e.keyCode > 40)
    ) {
      e.preventDefault();
    }
  };

  return (
    <>
      <div className="content">
        <div className="panel-header">
          <div className="page-inner py-5">
            <div className="d-flex align-items-left align-items-md-center flex-column flex-md-row">
              <div>
                <h2 className="pb-2 fw-bold">Edit Sub Category</h2>
                <ul className="breadcrumbs">
                  <li className="nav-home">
                    <Link to="/">
                      <i className="flaticon-home" />
                    </Link>
                  </li>
                  <li className="separator">
                    <i className="flaticon-right-arrow" />
                  </li>
                  <li className="nav-item">
                    <Link to="/category">Sub Category</Link>
                  </li>
                  <li className="separator">
                    <i className="flaticon-right-arrow" />
                  </li>
                  <li className="nav-item">
                    <a href="#">Edit Sub Category</a>
                  </li>
                </ul>
              </div>
              <div className="ml-md-auto py-2 py-md-0">
                <div className="row">
                  <div className="col-6">
                    <input
                      type="text"
                      name="dates"
                      className="form-control pull-right"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="page-inner mt--5">
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">Edit Sub Category</h4>
                </div>
                <div className="card-body">
                  <form onSubmit={formik.handleSubmit}>
                    <div className="col-md-12 col-xl-6">
                      <div className="form-group">
                        <label htmlFor="name">Sub Category Name:</label>
                        <input
                          className="form-control"
                          type="text"
                          id="name"
                          name="name"
                          value={formik.values.name}
                          onChange={formik.handleChange}
                        />
                        {formik.errors.name && formik.touched.name && (
                          <div className="text-danger">
                            {formik.errors.name}
                          </div>
                        )}
                      </div>
                      <div className="form-group">
                        <label htmlFor="unit_amount">Sub Category Price:</label>
                        <input
                          className="form-control"
                          type="number" // Change to "number"
                          id="unit_amount"
                          name="unit_amount"
                          value={formik.values.unit_amount}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          min={1} // Enforce minimum
                        />
                        {formik.errors.unit_amount &&
                          formik.touched.unit_amount && (
                            <div className="text-danger">
                              {formik.errors.unit_amount}
                            </div>
                          )}
                      </div>
                    </div>
                    <div>
                      <button
                        type="submit"
                        disabled={loading}
                        className="btn btn-primary m-2"
                      >
                        {loading ? "Updating..." : "Update"}
                      </button>
                      <Link to="/subcategory">
                        <button type="button" className="btn btn-danger">
                          Cancel
                        </button>
                      </Link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditCategory;
