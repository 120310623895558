import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useApiAxios from '../../api/useApiAxios';
import { Context } from "../../context/Context";


const Header = (props) => {

  const { logOutApi } = useApiAxios();

  const { userData } = useContext(Context);


  return (
    <div className="main-header">
      {/* Logo Header */}
      <div className="logo-header" data-background-color="light-blue">
        <Link to ="/" className="logo">
          <img
            src="/assets/img/logo-white.svg"
            alt="navbar brand"
            className="navbar-brand"
          />
        </Link>
        <button
          className="navbar-toggler sidenav-toggler ml-auto"
          type="button"
          data-toggle="collapse"
          data-target="collapse"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon">
            <i className="icon-menu" />
          </span>
        </button>
        <button className="topbar-toggler more">
          <i className="icon-options-vertical" />
        </button>
        <div className="nav-toggle">
          <button className="btn btn-toggle toggle-sidebar">
            <i className="icon-menu" />
          </button>
        </div>
      </div>
      {/* End Logo Header */}
      {/* Navbar Header */}
      <nav
        className="navbar navbar-header navbar-expand-lg"
        data-background-color="light-blue2"
      >
        <div className="container-fluid">
          
          <ul className="navbar-nav topbar-nav ml-md-auto align-items-center">
            <li className="nav-item toggle-nav-search hidden-caret">
              <a
                className="nav-link"
                data-toggle="collapse"
                href="#search-nav"
                role="button"
                aria-expanded="false"
                aria-controls="search-nav"
              >
                <i className="fa fa-search" />
              </a>
            </li>
           
           
         
            <li className="nav-item dropdown hidden-caret">
              <a
                className="dropdown-toggle profile-pic"
                data-toggle="dropdown"
                href="#"
                aria-expanded="false"
              >
                <div className="avatar-sm">
                  <img
                    src="/assets/img/profile.jpg"
                    alt="..."
                    className="avatar-img rounded-circle"
                  />
                </div>
              </a>
              <ul className="dropdown-menu dropdown-user animated fadeIn">
                <div className="dropdown-user-scroll scrollbar-outer">
                  <li>
                    <div className="user-box">
                      <div className="avatar-lg">
                        <img
                          src="/assets/img/profile.jpg"
                          alt="image profile"
                          className="avatar-img rounded"
                        />
                      </div>
                      <div className="u-text">
                        <h4>{userData.name}</h4>
                        <p className="text-muted">{userData.email}</p>

                        {userData.role === "vendor" ? (
                          <Link to="/"
                            className="btn btn-xs btn-secondary btn-sm"
                          >
                            View Profile
                          </Link>
                        ) : (
                          <Link to="/profile"
                            className="btn btn-xs btn-secondary btn-sm"
                          >
                            View Profile
                          </Link>
                        )}
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="dropdown-divider" />
                  

                    {userData.role === "vendor" ? (
                      <Link to="/" className="dropdown-item" >
                      My Profile
                    </Link>
                        ) : (
                          <Link to="/profile" className="dropdown-item" >
                      My Profile
                    </Link>
                        )}
                  
                    
                    <div className="dropdown-divider" />
                    <Link to className="dropdown-item" onClick={logOutApi}>
                      Logout
                    </Link>
                  </li>
                </div>
              </ul>
            </li>
          </ul>
        </div>
      </nav>
      {/* End Navbar */}
    </div>


  )
}

export default Header