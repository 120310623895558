import React, { useContext, useEffect, useState } from "react";
import useApiAxios from "../../api/useApiAxios";
import { Context } from "../../context/Context";
import { Link, useNavigate } from "react-router-dom";
import "@fortawesome/fontawesome-free/css/all.min.css";
import ReactPaginate from "react-paginate"; // Import ReactPaginate

const AppliedVendorList = () => {
  const {
    appliedVendorList,
    getAllMarket,
    approveVendorDate,
    rejectVendorDate,
    VendorWaitList,
  } = useApiAxios();
  const { userData } = useContext(Context);
  const [marketList, setMarketList] = useState([]);
  const [selectedMarketId, setSelectedMarketId] = useState(null);
  const [vendorList, setVendorList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState(0);
  const [appliedVendorPerPage, setAppliedVendorPerPage] = useState(10);
  const appliedVEndorPerPageOptions = [5, 10, 20, 50];

  useEffect(() => {
    const fetchMarketList = async () => {
      try {
        const response = await getAllMarket();
        setMarketList(response.data.data);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchMarketList();
  }, []);

  const fetchVendorList = async (marketId) => {
    try {
      setLoading(true);
      const response = await appliedVendorList(marketId);
      if (!response.error) {
        console.log(response.data.data); // Debugging line
        setVendorList(response.data.data);
        setError(null);
      } else {
        setError("Failed to fetch vendor list");
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleMarketChange = (e) => {
    const marketId = e.target.value;
    setSelectedMarketId(marketId);
    if (marketId) {
      fetchVendorList(marketId);
    }
  };

  const handleApprove = async (dateId) => {
    try {
      setLoading(true);
      const response = await approveVendorDate(dateId);
      if (!response.error) {
        fetchVendorList(selectedMarketId);
        setError(null);
      } else {
        setError("Failed to approve vendor");
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleReject = async (dateId) => {
    try {
      setLoading(true);
      const response = await rejectVendorDate(dateId);
      if (!response.error) {
        fetchVendorList(selectedMarketId);
        setError(null);
      } else {
        setError("Failed to reject vendor");
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };
  const handleWaitlist = async (dateId) => {
    try {
      setLoading(true);
      const response = await VendorWaitList(dateId);
      if (!response.error) {
        fetchVendorList(selectedMarketId);
        setError(null);
      } else {
        setError("Failed to reject vendor");
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  const formatDate = (dateString) => {
    if (!dateString) return "N/A";
    const date = new Date(dateString);
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Intl.DateTimeFormat("en-US", options).format(date);
  };

  const indexOfLastVendor = (currentPage + 1) * appliedVendorPerPage;
  const indexOfFirstVendor = indexOfLastVendor - appliedVendorPerPage;
  const currentVendors = vendorList.slice(
    indexOfFirstVendor,
    indexOfLastVendor
  );

  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
  };

  const handleVendorsPerPageChange = (event) => {
    setAppliedVendorPerPage(Number(event.target.value));
    setCurrentPage(0); // Reset to the first page when changing the number of vendors per page
  };

  const handleViewDetails = (vendorData) => {
    navigate(`/vendor-details`, { state: { vendor: vendorData } });
  };

  return (
    <div>
      <div className="panel-header">
        <div className="page-inner py-5">
          <div className="d-flex align-items-left align-items-md-center flex-column flex-md-row">
            <div>
              <h2 className="pb-2 fw-bold">Applied Vendors</h2>
              <ul className="breadcrumbs">
                <li className="nav-home">
                  <Link to="/">
                    <i className="flaticon-home" />
                  </Link>
                </li>
                <li className="separator">
                  <i className="flaticon-right-arrow" />
                </li>
                <li className="nav-item">
                  <a href="#">Applied Vendors </a>
                </li>
              </ul>
            </div>
            <div className="ml-md-auto py-2 py-md-0">
              <select
                onChange={handleMarketChange}
                value={selectedMarketId || ""}
                className="market-select"
              >
                <option value="" disabled>
                  Select a market
                </option>
                {marketList.map((market) => (
                  <option key={market._id} value={market._id}>
                    {market.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </div>
      <div className="page-inner mt--5">
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">All Dates</h4>
              </div>
              <div className="card-body">
                <div className="table-responsive">
                  <table
                    id="basic-datatables"
                    className="display table table-striped table-hover"
                  >
                    <thead>
                      <tr>
                        <th className="bussiness-name">Business Name</th>
                        <th className="category-prices">Date</th>
                        <th className="contact-person">Contact Person</th>
                        <th className="contact-number">Stall</th>
                        <th className="action">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentVendors.map((vendorData, index) => (
                        <tr key={index}>
                          <td>{vendorData.vendor?.businessName || "N/A"}</td>
                          <td>
                            {formatDate(vendorData.dateId?.date) || "N/A"}
                          </td>
                          <td>{vendorData.vendor?.contactPerson || "N/A"}</td>
                          <td>{vendorData.dateId?.totalStall || "N/A"}</td>
                          <td>
                            <div className="action-buttons">
                              <button
                                className="btn btn-success"
                                title="Approved"
                                onClick={() => handleApprove(vendorData?._id)}
                              >
                                Approve
                              </button>

                              <button
                                className="btn btn-info"
                                title="View Detail"
                                onClick={() => handleViewDetails(vendorData)}
                              >
                                View Detail
                              </button>

                              <button
                                className="btn btn-danger"
                                title="Declined"
                                onClick={() => handleReject(vendorData?._id)}
                              >
                                Decline
                              </button>

                              <button
                                className="btn btn-warning"
                                title="Waitlist"
                                onClick={() => handleWaitlist(vendorData?._id)}
                                disabled={vendorData?.waitList}
                              >
                                {vendorData?.waitList
                                  ? "On Waitlist"
                                  : "WaitList"}
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>

                  <div className="d-flex justify-content-between align-items-center pagination-container">
                    <ReactPaginate
                      previousLabel={"previous"}
                      nextLabel={"next"}
                      breakLabel={"..."}
                      pageCount={Math.ceil(
                        vendorList.length / appliedVendorPerPage
                      )}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"active"}
                    />
                    <div className="form-group d-flex align-items-center items-per-page">
                      <label htmlFor="vendorsPerPage">Vendors per page: </label>
                      <select
                        value={appliedVendorPerPage}
                        onChange={handleVendorsPerPageChange}
                        className="form-control m-2"
                        style={{ width: "auto", display: "inline-block" }}
                      >
                        {appliedVEndorPerPageOptions.map((option) => (
                          <option key={option} value={option}>
                            Show {option}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <style jsx>{`
        table {
          border-collapse: collapse;
          width: 100%;
          border-spacing: 0;
        }
        th,
        td {
          padding: 4px;
          text-align: left;
          border: 1px solid #ddd;
        }
        th {
          background-color: #f4f4f4;
        }
        .category-name {
          width: 20%;
        }
        .category-prices {
          width: 20%;
        }
        .contact-person {
          width: 25%;
        }
        .contact-number {
          width: 25%;
        }
        .action {
          width: 10%;
          text-align: center;
        }
        .action-buttons {
          display: flex;
          justify-content: center;
          gap: 8px;
        }
        .action-buttons .btn {
          font-size: 14px;
          cursor: pointer;
          padding: 6px 12px;
        }
        .btn-success {
          background-color: #28a745;
          border-color: #28a745;
        }
        .btn-info {
          background-color: #17a2b8;
          border-color: #17a2b8;
        }
        .btn-danger {
          background-color: #dc3545;
          border-color: #dc3545;
        }
        .market-select {
          padding: 8px;
          font-size: 16px;
          border: 1px solid #ddd;
          border-radius: 4px;
          background-color: #fff;
          cursor: pointer;
          transition: border-color 0.2s ease;
        }
        .market-select:focus {
          outline: none;
          border-color: #007bff;
          box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.25);
        }
      `}</style>
    </div>
  );
};

export default AppliedVendorList;
